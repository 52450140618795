import React, { useEffect, useRef } from "react";

export default function LoadingIndicator(props) {
    const fadeTimeout = useRef();

    useEffect(() => {
        if (!props.contained) {
            fadeTimeout.current = setTimeout(() => {
                $("body").animate({ opacity: 0.5 }, 300);
            }, 2000);
        }

        return () => {
            clearTimeout(fadeTimeout.current);
            $("body").animate({ opacity: 1 }, 300);
            setTimeout(() => {
                $("body").css({ opacity: 1 });
            }, 300);
        };
    }, []);

    return (
        <div className={"loading-indicator" + (props.contained ? " contained" : "")} style={props.style || {}}><div className="progress-dots"><span style={{backgroundColor: props.dotColor ? (props.dotColor) : ""}} className="progress-dot-1" /><span style={{backgroundColor: props.dotColor ? (props.dotColor) : ""}} className="progress-dot-2" /><span style={{backgroundColor: props.dotColor ? (props.dotColor) : ""}} className="progress-dot-3" /></div></div>
    );
}