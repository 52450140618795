import AdminService from "./Admin";
import { APIRequest } from "./API";
import Session from "./Session";
import Settings from "./Settings";
import L10n from "./Locale";
import Helper from "./Helper";

const CACHE_LIFESPAN = 10 * 60 * 1000;
const LOCALHOSTDEVPREFIX = location.hostname == "localhost" ? "http://localhost:8081" : "";

export function GetCached(key) {
    const cache = JSON.parse(sessionStorage.getItem("content_cache") || "{}");
    if ((cache[key] && cache[key].timestamp < Date.now() - CACHE_LIFESPAN) || !cache[key]) {
        return null;
    }

    return cache[key].content;
}

export function SetCached(key, value) {
    const cache = JSON.parse(sessionStorage.getItem("content_cache") || "{}");
    cache[key] = { content: typeof value !== "string" ? JSON.stringify(value) : value, timestamp: Date.now() };
    sessionStorage.setItem("content_cache", JSON.stringify(cache));
}

export default class ContentHelper {
    static async InvalidateCache() {
		const language = sessionStorage.getItem("locale");
        sessionStorage.setItem("content_cache", "{}");
		sessionStorage.setItem("bootstrapped", null);
		sessionStorage.setItem("settings_cache", null);
		L10n.SetLanguage(language);
		sessionStorage.setItem("locale", language);
    }

    static GetString(slug, locale=null) {
        return new Promise((resolve, reject) => {
            const cached = GetCached(slug);

            if (cached !== null) {
                resolve(cached);
                return;
            }

            APIRequest("cms/get", { slug, locale: locale || L10n.GetLanguage() }).then(response => {
                if (response.data) SetCached(slug, response.data?.content);
                resolve(response.data?.content);
            });
        });
    }

    static async GetJSON(slug) {
        const json = await ContentHelper.GetString(slug);
        return JSON.parse(json);
    }

    static async GetLogoImg(className, height, styles=null) {
        
        if (height == undefined) {
            height = await Settings.Get("LOGO_HEIGHT", 65);

            if (Helper.IsResponsive()) {
                height *= 0.75;
            }
        }

        const cached = GetCached("cms/get-logo");
        
        if (cached) {
            return <img className={className} height={height} style={{ width: "auto", height: height + "px", ...styles}} src={cached.substring(0, 4) == "http" ? cached : (cached[0] == "/" ? LOCALHOSTDEVPREFIX + cached : "/static/images/" + cached)} />;
        }
        
        const logoResponse = await APIRequest("cms/get-logo");
        SetCached("cms/get-logo", logoResponse.data);

        return <img className={className} height={height} style={{ width: "auto", height: height + "px", ...styles}} src={logoResponse.data.substring(0, 4) == "http" ? logoResponse.data : (logoResponse.data[0] == "/" ? LOCALHOSTDEVPREFIX + logoResponse.data : "/static/images/" + logoResponse.data)} />;
    }

    static GetFaviconURL() {
        return new Promise((resolve, reject) => {
            const cached = GetCached("cms/get-favicon");
            
            if (cached) {
                resolve(cached);
                return;
            }
            
            APIRequest("cms/get-favicon").then(response => {
                SetCached("cms/get-favicon", response.data);
                resolve(response.data);
            });
        });
    }

    static GetSocialMediaLinks() {
        return new Promise((resolve, reject) => {
            const cached = GetCached("cms/get-social-media-networks");
            if (cached) {
                resolve(<>
                    {JSON.parse(cached).map((network, idx) => {
                        return <a key={idx} rel="noreferrer" href={network.url} target="_blank">{network.name}</a>;
                    })}
                </>);
                return;
            }

            APIRequest("cms/get-social-media-networks").then(response => {
                const links = JSON.parse(response.data);

                SetCached("cms/get-social-media-networks", response.data);

                resolve(<>
                    {links.map((network, idx) => {
                        return <a key={idx} rel="noreferrer" href={network.url} target="_blank">{network.name}</a>;
                    })}
                </>);
            });
        });
    }


    static async GetStrings(slugsArray) {
        const response = await APIRequest("cms/get-multiple", { slugsArray, locale: L10n.GetLanguage() });

        if (!response.status || !response.data) {
            return "";
        }

        const output = {};
        for (let item of response.data) {
            output[item.slug] = item.content;
        }

        return output;
    }

    static async GetNumber(slug) {
        const string = await ContentHelper.GetString(slug);
        return parseFloat(string);
    }

    static async GetFAQAll() {
        const response = await APIRequest("cms/faq-get-all", { locale: L10n.GetLanguage() });
        const keys = response.data.filter(item => item.slug.match(/faq-\d+-titulo/gi)).map(item => item.slug);
        const titles = response.data.filter(item => item.slug.match(/faq-\d+-titulo/gi)).map(item => item.content);
        const content = response.data.filter(item => item.slug.match(/faq-\d+-contenido/gi)).map(item => item.content);
        return keys.map((k, i) => {
            return {
                titulo: titles[i],
                contenido: content[i],
                key: k
            };
        });
    }

}
