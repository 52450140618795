import { useState, useEffect, useRef, useCallback } from "react";
import { useParams } from "react-router-dom";
import PreorderTicket from './PreorderTicket'
import LoadingIndicator from "./LoadingIndicator";
import L10n from "../services/Locale";
import { PreorderSelectionContext } from "../context/PreorderSelectionContext";
import { APIRequest } from "../services/API";
import { Content } from "./Content";
import Helper from "../services/Helper";
import Events from "../services/Events";
import Session from "../services/Session";
import Modal from "./Modal";
import MaterialIcon from "./MaterialIcon";
import Settings from "../services/Settings";
import SubscriptionsService from "../services/Subscriptions.jsx";

export default function Extras(props) {
    const [ loading, setLoading ] = useState(true);
    const [ event, setEvent ] = useState();
    const [ booking, setBooking ] = useState();
	const [ total, setTotal ] = useState(0);
	const [ formattedTotal, setFormattedTotal ] = useState();
    const [ preorderSelection, setPreorderSelection ] = useState([]);
    const [ extras, setExtras ] = useState([]);
    const [ categories, setCategories ] = useState([]);
    const [ modalQty, setModalQty ] = useState(0);
    const [ subscriptionsEnabled, setSubscriptionsEnabled ] = useState(false);
    const categoriasScrollAnimIntervalRef = useRef();
    const modalRef = useRef();
	const slug = useParams().slug;

    const onWheel = e => {
        if (!Helper.IsResponsive()) {
            const delta = e.originalEvent.deltaY * (e.originalEvent.webkitDirectionInvertedFromDevice ? -1 : 1);
            const rect = $(".sidebar .inner")[0].getBoundingClientRect();
            let offset = rect.top;

            if (rect.bottom > window.innerHeight - 10 && delta > 0) {
                offset -= delta;
            } else if (rect.top < $("header.desktop").outerHeight(true) + 20 && delta < 0) {
                offset -= delta;
            }

            offset = Math.min($("header.desktop").outerHeight(true) + 20, Math.max(window.innerHeight - 10 - rect.height, offset));

            console.log(offset);

            $(".sidebar .inner").css({ top: offset });
        }
    };

    const onScroll = e => {
        let done = false;
        
        if ($(".main > .categorias").attr("data-inhibit") == "true") {
            return;
        }
        
        $(".main .extras .category").each(function(e) {
            if (!done && this.getBoundingClientRect().top > 80) {
                done = true;
                let m = this.id.match(/.+?-(\d+)/);
                if (m && m.length > 1) {
                    $(".main > .categorias a").removeClass("active");
                    $(".main > .categorias a[href='#" + m[1] + "']").addClass("active");
                }
            }
        });

        if ($(".categorias").length) {
            clearInterval(categoriasScrollAnimIntervalRef.current);
            $(".categorias")[0].scrollTarget = $(".categorias .inner a.active").offset()?.left;
            $(".categorias")[0].currentScrollOffset = $(".categorias").scrollLeft();

            categoriasScrollAnimIntervalRef.current = setInterval(() => {
                if (!$(".categorias").length) {
                    clearInterval(categoriasScrollAnimIntervalRef.current);
                    return;
                }

                $(".categorias")[0].currentScrollOffset += ($(".categorias")[0].scrollTarget - $(".categorias")[0].currentScrollOffset) / 10;
                $(".categorias").scrollLeft($(".categorias")[0].currentScrollOffset);

                if ($(".categorias")[0].scrollTarget == $(".categorias")[0].currentScrollOffset) {
                    clearInterval(categoriasScrollAnimIntervalRef.current);
                }
            }, 5);
        }
    };

    const onResize = () => {
        if (Helper.IsResponsive()) {
            $(".venue-extras .sidebar:not(.active) .inner").css({
                top: window.innerHeight - 130
            });
            $(".venue-extras .sidebar.active .inner").css("top", "");
        }
    };

    useEffect(() => {
        let total = 0;
        preorderSelection.forEach(it => {
            total += it.quantity * it.price;
        });
        setTotal(total);
    }, [ preorderSelection ]);

    useEffect(() => {
        setFormattedTotal(Helper.FormatAmount(total / 100));
    }, [ total ]);

    useEffect(() => {
        $(".categorias .inner").css({
            width: "",
            display: "",
            justifyContent: "",
            alignItems: ""
        });

        $(".categorias .inner a").css({
            minWidth: "",
            flex: ""
        });

        let totalWidth = 0;
        $(".main > .categorias a").each(function() {
            totalWidth += $(this).outerWidth(true);
        });

        if (totalWidth < $(".main > .categorias").width()) {
            $(".main > .categorias .inner").css({
                display: "flex",
                alignItems: "center",
                justifyContent: "space-evenly"
            });
            $(".main > .categorias a").css({ flex: 1 });
        }
        
        $(".main > .categorias .inner").css({ width: $(".main > .categorias").width() });
        if (!Helper.IsResponsive()) $("#spotnow").css({ minHeight: $(".sidebar .inner").outerHeight(true) + 25 });

        if ($(".sidebar .inner")[0]?.getBoundingClientRect().bottom > window.innerHeight) {
            $(".sidebar .inner").css({ position: "relative" });
        } else {
            $(".sidebar .inner").css({ position: "fixed" });
        }

        onScroll();
        onResize();
        
        $(window).on("resize", onResize);

        return () => {
            $(window).off("resize", onResize);
            $("#spotnow").css({ minHeight: "" });
			$(".injected-css-extras").remove();
        };
    }); // After render.

    useEffect(() => {
        Events.GetEventSlot(Session.GetBookingData("selected_availability_id")).then(event => {
            Promise.all([
                APIRequest("events/get-extras", { event_id: event.id, availability_id: Session.GetBookingData("selected_availability_id"), flat: 1 }),
                APIRequest("events/get-extras-categories", { event_id: event.id, availability_id: Session.GetBookingData("selected_availability_id") })
            ]).then(([extras, categories]) => {
                setBooking(Session.GetBookingData());
                setEvent(event);
                setExtras(extras.data);
                setCategories(categories.data);

                setPreorderSelection(Session.GetBookingData("preorder_selection") || []);

                setLoading(false);
            });
        });

        Settings.Get("LOGO_HEIGHT", 65).then(height => {
            $("body").append('<div class="injected-css"><style>body.scrolled .venue-extras .main > .categorias .inner { top: ' + (parseInt(height) + 5) + 'px; }</style></div>');
        });

        
        SubscriptionsService.IsEnabled().then(subscriptionsEnabled => {
            setSubscriptionsEnabled(subscriptionsEnabled);
        });

        $("header").addClass("simple");
        $(window).on("scroll", onScroll);
        $(window).on("resize", onResize);
        $(window).on("wheel", onWheel);

        return () => {
			$(".injected-css").remove();
			$("header").removeClass("simple");
            $(window).off("resize", onResize);
            $(window).off("scroll", onScroll);
            $(window).off("wheel", onWheel);
		};
    }, []);

    const changeSelection = useCallback(newSelection => {
        setPreorderSelection(newSelection);
    }, [ setPreorderSelection ]);

    if (loading) return <LoadingIndicator />;

    const hasTotal = preorderSelection.filter(e => e.quantity > 0).length > 0;
    
    return <div className="venue-extras">
        <PreorderSelectionContext.Provider
			value={{
				preorderSelection,
				changeSelection,
				formattedTotal,
				total
		    }}
        >
            <div className="sidebar">
                <div className="inner">
                    <a href="#" className="ver-detalle" onClick={e => {
                        e.preventDefault();
                        $(".sidebar").toggleClass("active");
                        if ($(".sidebar").hasClass("active")) {
                            $("body").addClass("scroll-lock");
                        } else {
                            $("body").removeClass("scroll-lock");
                        }
                        onResize();
                        onResize();
                    }}>{L10n.__("Ver detalle de la reserva")} <img src="/static/icons/desplegable-preguntas.png" /></a>
                    <div className="box">
                        <div className="image" style={{ backgroundImage: "url(" + (event.negocio.image_url[0] == "/" ? event.negocio.image_url : "/static/images/eventos/" + event.negocio.image_url) + ")" }} />
                        <div className="negocio-name">{event.negocio.name}</div>
                        {event.negocio.address && <div className="negocio-address">{event.negocio.address}</div>}
                        <PreorderTicket event={event} booking={booking} />
                    </div>
                    <div className="boton-confirmar-container"><a href="#" className={"btn btn-brown boton-confirmar" + (hasTotal ? " space-between" : "")} onClick={e => {
                        e.preventDefault();
                        Session.SetBookingData("preorder_selection", preorderSelection);

                        if (event.offers_upselling == 1) {
                            location.href = "/venue/" + slug + "/extras/otros";
                        } else {
                            Session.RedirectAuthenticated("/pago");
                        }
                    }}>
                        {hasTotal && <>{L10n.__("Confirmar total")} <span>{formattedTotal}</span></>}
                        {!hasTotal && L10n.__("Continuar sin extras")}
                    </a></div>
                </div>
            </div>
            <div className="main">
                <div className="categorias">
                    <div className="inner">
                        {categories.map((cat, catIdx) => {
                            return <a href={"#" + cat.id} key={"catbtn" + catIdx + Helper.SngNormalize(cat.title)} onClick={e => {
                                e.preventDefault();
                                $(".main > .categorias").attr("data-inhibit", "true");
                                $("html, body").animate({
                                    scrollTop: $("#category-" + cat.id).offset().top - $(".main").offset().top + 40
                                }, 500, null, () => {
                                    $(".main > .categorias").attr("data-inhibit", "false");
                                });
                                $(".categorias a").removeClass("active");
                                $(".categorias a[href='#" + cat.id + "']").addClass("active");
                            }}>{cat.title}</a>;
                        })}
                    </div>
                </div>
                <div className="intro-extras"><Content slug="intro-extras" /></div>
                <div className="extras">
                    {categories.map((cat, catIdx) => {
                        return <div className="category" id={"category-" + cat.id} key={"cat" + catIdx + Helper.SngNormalize(cat.title)}>
                            <h2>{cat.title}</h2>
                            <div className="description">{cat.description}</div>
                            <div className="category-extras">
                                {extras.filter(e => e.category_id == cat.id).map((extra, extraIdx) => {
                                    const selection = preorderSelection.find(i => i.id == extra.id);
                                    return <div
                                    onClick={e => {
                                        e.preventDefault();
                                        extra.initialQuantity = preorderSelection.find(i => i.id == extra.id)?.quantity || 0;
                                        modalRef.current.setData(extra);
                                        setModalQty(extra.initialQuantity || 1);
                                        modalRef.current.open();
                                        modalRef.current.setTitle(extra.name);
                                    }}
                                    className="extra cover-zoom-listener" key={"extra" + extraIdx + Helper.SngNormalize(extra.name)}>
                                        {selection?.quantity > 0 && <div className="quantity">{selection.quantity}</div>}
                                        <div className="image cover-zoom" style={{ backgroundImage: "url(" + extra.image_url + ")" }} />
                                        <h3>{extra.name}</h3>
                                        <div className="description" dangerouslySetInnerHTML={{ __html: extra.description }} />
                                        <div className="price-tag">{Helper.FormatAmount(extra.price/100.0)}</div>
                                    </div>;
                                })}
                            </div>
                        </div>;
                    })}
                </div>
            </div>
            <Modal
                ref={ref => modalRef.current = ref}
                renderContent={(modal, extra) => {
                    return <div className="extras-modal">
                        <div className="image" style={{ backgroundImage: "url(" + extra.image_url + ")" }} />
                        <div className="description" dangerouslySetInnerHTML={{ __html: extra.description }} />
                        <div className="controls">
                            <div className="quantity-selector">
                                <MaterialIcon name="remove" className="quantity-button minus" onClick={e => {
                                    e.preventDefault();
                                    setModalQty(Math.max(0, modalQty - 1));
                                }} />
                                <div className="quantity">{modalQty}</div>
                                <MaterialIcon name="add" className="quantity-button plus" onClick={e => {
                                    e.preventDefault();
                                    setModalQty(modalQty + 1);
                                }} />
                                <div className="button-confirm-container">
                                    <a href="#" className="btn btn-brown confirm" onClick={e => {
                                        e.preventDefault();
                                        const n = [...preorderSelection];
                                        const i = preorderSelection.findIndex(e => e.id == extra.id);
                                        if (i != -1) {
                                            n[i].quantity = modalQty;
                                        } else {
                                            const e = {...extra};
                                            e.quantity = modalQty;
                                            n.push(e);
                                        }
                                        setPreorderSelection(n);
                                        modal.close();
                                    }}>{L10n.__(modalQty > extra.initialQuantity ? "Añadir" : "Actualizar")} {<span>{Helper.FormatAmount(modalQty * extra.price / 100.0)}</span>}</a>
                                </div>
                            </div>
                        </div>
                    </div>;
                }}
            />
        </PreorderSelectionContext.Provider>
    </div>;
}
