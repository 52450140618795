import React from "react";
import { Link } from "react-router-dom";
import { APIRequest } from "./API";
import Helper from "./Helper";
import Icon from '../components/Icon';
import L10n from "./Locale";
import ContentHelper from "./ContentHelper";

export default class Session {
	static BoostrapLock = false;
	static Bootstrapped = false;
	static BootstrapData = {};
	
	static async Bootstrap(language=null) {
		if (Session.Bootstrapped) {
			if (Session.BootstrapData.language == language) return Session.BootstrapData;
		}
		
		try {
			if (sessionStorage.getItem("bootstrapped") === "true") {
				const data = JSON.parse(sessionStorage.getItem("bootstrap_data") || "{}");
				if (data.language == language) return data;
			}
		} catch (e) {
			sessionStorage.setItem("bootstrapped", false);
		}

		if (Session.BoostrapLock) {
			return new Promise((resolve, reject) => {
				setTimeout(() => {
					resolve(Session.Bootstrap(language));
				}, 500);
			});
		}
		
		sessionStorage.setItem("bootstrapped", false);
		Session.Bootstrapped = false;

		Session.BoostrapLock = true;
		const response = await APIRequest("session/bootstrap", { locale: language || sessionStorage.getItem("locale") || L10n.GetAvailableLanguages()[0], user_id: localStorage.getItem("user_id"), session_id: localStorage.getItem("session_id") });
		L10n.SetLanguage(response.data?.language);
		sessionStorage.setItem("bootstrap_data", JSON.stringify(response.data));
		Session.BootstrapData = response.data;
		sessionStorage.setItem("bootstrapped", true);
		Session.BoostrapLock = false;
		Session.Bootstrapped = true;

		return response.data;
	}

	static GetCachedBootstrapData() {
		if (Session.Bootstrapped) return Session.BootstrapData || {};
		try {
			return JSON.parse(sessionStorage.getItem("bootstrap_data")) || {};
		} catch (e) {
			return {};
		}
	}

	static async GetBootstrapData() {
		if (Session.Bootstrapped) return Session.BootstrapData;
		let data = {};
		if (sessionStorage.getItem("bootstrapped") === "true") {
			data = Session.GetCachedBootstrapData();
		} else {
			data = await Session.Bootstrap();
		}
		return data;
	}

	static AlterBootstrapData(key, value) {
		let data = Session.GetCachedBootstrapData();
		if (data == null) data = {};
		data[key] = value;
		sessionStorage.setItem("bootstrap_data", JSON.stringify(data));
		Session.BootstrapData = data;
	}

    static ClearBookingData() {
		sessionStorage.removeItem('booking_data');
		sessionStorage.removeItem('current_order_id');
    }

	static SetBookingData(key, value) {
		if(typeof value === 'undefined') {
			sessionStorage.setItem('booking_data', JSON.stringify(key));
		} else {
			let booking_data = JSON.parse(sessionStorage.getItem('booking_data') || "{}");
			if (value === null) {
				delete booking_data[key];
			} else {
				booking_data[key] = value;
			}
			sessionStorage.setItem('booking_data', JSON.stringify(booking_data));
		}
	}

	static BootstrapBookingData() {
		let booking_data = JSON.parse(sessionStorage.getItem('booking_data') || "{}");

		if(!booking_data.pax) booking_data.pax = 1;
		if(!booking_data.selected_date) booking_data.selected_date = Helper.GetISODate(new Date());

		sessionStorage.setItem('booking_data', JSON.stringify(booking_data));
	}

	static GetBookingData(key) {
		Session.BootstrapBookingData();

		let booking_data = JSON.parse(sessionStorage.getItem('booking_data') || "{\"pax\": 1, \"selected_date\": \"\", \"selected_slot\": \"\"}");
		
		if(typeof key === 'undefined') {
			return booking_data;
		}
		
		return booking_data[key];
	}

    static async Login(login, password, persistent=false) {
		const result = await APIRequest('users/authenticate', { login, password, persistent: persistent ? 1 : 0 });
		return result;
	}

	static async RequireLogin(redirectURL) {
		sessionStorage.setItem("login_redirect", redirectURL || location.href);
		location.href = "/login";
	}

	static async RequireAccount(redirectURL) {
		sessionStorage.setItem("login_redirect", redirectURL || location.href);
		location.href = "/registro";
	}

	static async Logout() {
		await APIRequest('users/logout', null);
		localStorage.setItem("session_id", null);
		localStorage.setItem("user_id", null);
		Session.BootstrapData = null;
		Session.Bootstrapped = false;
		sessionStorage.clear();
	}

	static async Register(login, password, name, surname, phone, country) {
		const result = await APIRequest('users/register', { login, password, name, surname, phone, country });
		return result;
	}

	static async AttemptUserActivation(token) {
		const result = await APIRequest("users/activate", { token });
		return result;
	}

	static GetSession() {
		return new Promise(async(resolve, _reject) => {
			if (sessionStorage.getItem("session_check_lock")) {
				if (Date.now() - sessionStorage.getItem("session_check_lock_set_time") > 5000) {
					sessionStorage.removeItem("session_check_lock");
				}

				setTimeout(() => {
					Session.GetSession().then(session => resolve(session));
				}, 1000);
				return;
			}

			sessionStorage.setItem("session_check_lock", 1);
			sessionStorage.setItem("session_check_lock_set_time", Date.now());

			const elapsed = Date.now() - parseInt(sessionStorage.getItem("last_session_check"));
			let cachedData = null;
			try {
				cachedData = JSON.parse(sessionStorage.getItem("cached_session_data"));
			} catch(e) {}

			if (sessionStorage.getItem("cached_session_data") && elapsed < 5000 && cachedData) {
				sessionStorage.removeItem("session_check_lock");
				resolve(cachedData);
			} else {
				const data = {};

				APIRequest("users/check-cache-invalidation").then(response => {
					if (response.status) {
						if (response.data.invalidate > (parseInt(sessionStorage.getItem("last_cache_invalidation") || 0))) {
							sessionStorage.setItem("last_cache_invalidation", response.data.invalidate);
							ContentHelper.InvalidateCache();
						}
					}
				});

				if (localStorage.getItem("user_id") && localStorage.getItem("session_id")) {
					data["user_id"] = localStorage.getItem("user_id");
					data["session_id"] = localStorage.getItem("session_id");
				}

				const result = await APIRequest('users/get-session', data);
				if(result.status && result.data && result.data.id) {
					sessionStorage.setItem('is_logged_in', '1');
				} else {
					sessionStorage.setItem('is_logged_in', '0');
				}
				sessionStorage.setItem("last_session_check", ""+Date.now());
				sessionStorage.setItem("cached_session_data", JSON.stringify(result.data));
				sessionStorage.removeItem("session_check_lock");
				resolve(result.data);
			}
		});
	}

	static CheckIsLoggedIn() {
		return new Promise(async(resolve, reject) => {
			const session = await Session.GetSession();
			if(!session) sessionStorage.removeItem('is_logged_in');
			const is_logged_in = sessionStorage.getItem('is_logged_in');
			resolve(session || (is_logged_in && parseInt(is_logged_in) === 1));
		});
	}

	static ExistsBooking() {
		return Session.GetBookingData() && Session.GetBookingData('selected_event');
	}

	static async RedirectAuthenticated(url) {
		const session = await Session.GetSession();
		if(session && session.id) {
			sessionStorage.setItem('user_data', JSON.stringify(session));
			window.location.href = url;
		} else {
			sessionStorage.setItem("login_redirect", url);
			window.location.href = "/login";
		}
	}

	static RenderUserActions(onClick=() => {}, use_list=false) {
		const is_logged_in = sessionStorage.getItem('is_logged_in') && parseInt(sessionStorage.getItem('is_logged_in')) === 1;
		const session = JSON.parse(sessionStorage.getItem("cached_session_data"));

		if(use_list) {
			return (
				<React.Fragment>
					{ !is_logged_in && <li><Link className="nav-link nav-btn" onClick={onClick }to="/login">{L10n.__("Login")}</Link></li> }
					{ !is_logged_in && <li><Link className="nav-link nav-btn" onClick={onClick }to="/registro">{L10n.__("Registrarse")}</Link></li> }
					{ is_logged_in && Session.ExistsBooking() && <li><Link className="nav-link nav-btn" onClick={onClick} to="/pago">{L10n.__("Completar reserva")}</Link></li> }
					{session != null && is_logged_in && (session.is_partner == true || session.is_superuser == true) && <li><Link className="nav-link nav-btn" to="/admin" onClick={onClick}>{L10n.__("Panel admin")}</Link></li>}
					{ is_logged_in && session != null && <li><Link className="nav-link nav-btn" to="/mi-cuenta">{L10n.__("Mi cuenta")}</Link></li> }
				</React.Fragment>
			);
		}

		return (
			<div className="user-actions">
				{ is_logged_in && <Link className="nav-link nav-btn" onClick={onClick} to="/mi-cuenta"><Icon name={is_logged_in ? 'user-selected' : 'user'} size={2} />{L10n.__("Mi cuenta")}</Link> }
				{ !is_logged_in && <Link onClick={onClick} to="/login"><Icon name={is_logged_in ? 'user-selected' : 'user'} size={2} />{L10n.__("Login")}</Link> }
				{ !is_logged_in && <Link onClick={onClick} to="/registro">{L10n.__("Registrarse")}</Link> }
				{ is_logged_in && Session.ExistsBooking() && <Link onClick={onClick} to="/pago">{L10n.__("Completar reserva")}</Link>}
				{session && session.is_superuser == true && <Link to="/admin" onClick={onClick}>{L10n.__("Panel admin")}</Link>}
			</div>
		)
	}

	static async GetAccountEvents() {
		const result = await APIRequest('users/get-account-events');
		return result.data;
	}

	static async GetPartnerCalendar(eventID) {
		const result = await APIRequest('users/get-partner-calendar', { event_id: eventID });
		return result.data;
	}

	static async GetPartnerAvailabilityCalendar(eventID) {
		const result = await APIRequest('users/get-partner-availability-calendar', { event_id: eventID });
		return result.data;
	}


	static async RequestPasswordChange(login) {
		const result = await APIRequest('users/request-password-change', { login });
		return result.status;
	}

    static async PerformPasswordChange(password, token) {
		const result = await APIRequest('users/perform-password-change', { password, token });
		return result.status;
    }

	static async DirectPasswordChange(password) {
		const result = await APIRequest('users/direct-password-change', { password });
		return result.status;
	}

	static async ClearCache() {
		sessionStorage.removeItem("cached_session_data");
		sessionStorage.removeItem("last_session_check");
	}

	static async SaveProfileData(data) {
		Session.ClearCache();

		const result = await APIRequest("users/save-profile-data", data);
		return result;
	}

	static async AttemptEmailChange(token) {
		const result = await APIRequest("users/change-email", { token });
		return result;
	}

	static async GetPromoCodes() {
		const result = await APIRequest("users/promo-codes", {});
		return result.data;
	}

	static async GetUserID() {
		const session = await Session.GetSession();
		return session?.id;
	}
}
