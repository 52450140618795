import L10n from "./Locale";
import Session from "./Session";

export default class Helper {
	static DATE_VERBOSE_SHORTEST = 0;
	static DATE_VERBOSE_SHORT = 1;
	static DATE_VERBOSE_LONG = 2;
	static DATE_VERBOSE_LONGEST = 3;

	static MAX_RESPONSIVE_WIDTH = 768;

	static IsResponsive() {
		return window.innerWidth <= Helper.MAX_RESPONSIVE_WIDTH;
	}

	static MinutesToTimeString(minutes) {
		if (!minutes || isNaN(parseInt(minutes))) return "";

		let hours = 0;
		minutes = parseInt(minutes);

		while (minutes >= 60) {
			minutes -= 60;
			hours++;
		}

		return hours + ":" + minutes;
	}

	static PadStart(value, length, padString) {
		while (("" + value).length < length) value = padString + "" + value;
		return value;
	}

	static Hash(string) {
		string = "" + string;
		for (var i = 0, hash = 0; i < string.length; i++) hash = (Math.imul(31, hash) + string.charCodeAt(i)) | 0;
		return hash;
	}

    static SHA256(ascii) {
        function rightRotate(value, amount) {
            return (value>>>amount) | (value<<(32 - amount));
        };

        var mathPow = Math.pow;
        var maxWord = mathPow(2, 32);
        var lengthProperty = 'length'
        var i, j; // Used as a counter across the whole file
        var result = ''

        var words = [];
        var asciiBitLength = ascii[lengthProperty]*8;

        //* caching results is optional - remove/add slash from front of this line to toggle
        // Initial hash value: first 32 bits of the fractional parts of the square roots of the first 8 primes
        // (we actually calculate the first 64, but extra values are just ignored)
        var sha256 = {};
        var hash = sha256.h = sha256.h || [];
        // Round constants: first 32 bits of the fractional parts of the cube roots of the first 64 primes
        var k = sha256.k = sha256.k || [];
        var primeCounter = k[lengthProperty];
        /*/
    var hash = [], k = [];
    var primeCounter = 0;
    //*/

        var isComposite = {};
        for (var candidate = 2; primeCounter < 64; candidate++) {
            if (!isComposite[candidate]) {
                for (i = 0; i < 313; i += candidate) {
                    isComposite[i] = candidate;
                }
                hash[primeCounter] = (mathPow(candidate, .5)*maxWord)|0;
                k[primeCounter++] = (mathPow(candidate, 1/3)*maxWord)|0;
            }
        }

        ascii += '\x80' // Append Ƈ' bit (plus zero padding)
        while (ascii[lengthProperty]%64 - 56) ascii += '\x00' // More zero padding
        for (i = 0; i < ascii[lengthProperty]; i++) {
            j = ascii.charCodeAt(i);
            if (j>>8) return; // ASCII check: only accept characters in range 0-255
            words[i>>2] |= j << ((3 - i)%4)*8;
        }
        words[words[lengthProperty]] = ((asciiBitLength/maxWord)|0);
        words[words[lengthProperty]] = (asciiBitLength)

        // process each chunk
        for (j = 0; j < words[lengthProperty];) {
            var w = words.slice(j, j += 16); // The message is expanded into 64 words as part of the iteration
            var oldHash = hash;
            // This is now the undefinedworking hash", often labelled as variables a...g
            // (we have to truncate as well, otherwise extra entries at the end accumulate
            hash = hash.slice(0, 8);

            for (i = 0; i < 64; i++) {
                var i2 = i + j;
                // Expand the message into 64 words
                // Used below if 
                var w15 = w[i - 15], w2 = w[i - 2];

                // Iterate
                var a = hash[0], e = hash[4];
                var temp1 = hash[7]
                    + (rightRotate(e, 6) ^ rightRotate(e, 11) ^ rightRotate(e, 25)) // S1
                + ((e&hash[5])^((~e)&hash[6])) // ch
                    + k[i]
                    // Expand the message schedule if needed
                    + (w[i] = (i < 16) ? w[i] : (
                        w[i - 16]
                            + (rightRotate(w15, 7) ^ rightRotate(w15, 18) ^ (w15>>>3)) // s0
                            + w[i - 7]
                            + (rightRotate(w2, 17) ^ rightRotate(w2, 19) ^ (w2>>>10)) // s1
                        )|0
                );
                // This is only used once, so *could* be moved below, but it only saves 4 bytes and makes things unreadble
                var temp2 = (rightRotate(a, 2) ^ rightRotate(a, 13) ^ rightRotate(a, 22)) // S0
                + ((a&hash[1])^(a&hash[2])^(hash[1]&hash[2])); // maj

                hash = [(temp1 + temp2)|0].concat(hash); // We don't bother trimming off the extra ones, they're harmless as long as we're truncating when we do the slice()
                hash[4] = (hash[4] + temp1)|0;
            }

            for (i = 0; i < 8; i++) {
                hash[i] = (hash[i] + oldHash[i])|0;
            }
        }

        for (i = 0; i < 8; i++) {
            for (j = 3; j + 1; j--) {
                var b = (hash[i]>>(j*8))&255;
                result += ((b < 16) ? 0 : '') + b.toString(16);
            }
        }
        return result;
    };

	static SetDocumentTitle(title) {
		if (title) {
			document.title = title + " | " + SITE_TITLE;
		} else {
			document.title = SITE_TITLE;
		}
	}

	static FormatISODate(str, verbose) {
		if (!verbose || typeof verbose === "undefined") {
			verbose = Helper.DATE_VERBOSE_SHORTEST;
		}
		let date = new Date();

		if (str) {
			const s = str.split("-");
			date = new Date(s[0], s[1] - 1, s[2]);
		}

		if (verbose === Helper.DATE_VERBOSE_SHORTEST || verbose === Helper.DATE_VERBOSE_SHORT) {
			return date.toLocaleDateString(L10n.GetLanguage(), { year:"numeric", month: "2-digit", day: "2-digit" });
		} else if (verbose === Helper.DATE_VERBOSE_LONG) {
			return date.toLocaleDateString(L10n.GetLanguage(), { year: "numeric", month: "long", day: "numeric" });
		} else if (verbose === Helper.DATE_VERBOSE_LONGEST) {
			return date.toLocaleDateString(L10n.GetLanguage(), { weekday: "long", year: "numeric", month: "long", day: "numeric" });
		}
	}

	static AfterRender(callback) {
		setTimeout(() => {
			requestAnimationFrame(() => {
				callback(jQuery);
			});
		});
	}

	static UCFirst(str) {
        if (!str) return "";
		return str.substring(0, 1).toUpperCase() + str.substring(1);
	}

	static Ellipsis(str, maxLength, renderReadMore = () => { return null; }) {
		return <><div dangerouslySetInnerHTML={{ __html: str.substring(0, maxLength) + (str.length > maxLength ? "…" : "") }}/>{str.length > maxLength && renderReadMore()}</>;
	}

	static FormatDate(date, verbose) {
		if (typeof date === "string") {
			return Helper.FormatISODate(date, verbose);
		} else {
			return Helper.FormatISODate(Helper.GetISODate(date), verbose);
		}
	}

	static CreateDateCompatible(ISODateString) {
		const components = ISODateString.split(" ")[0].split("-");
		return new Date(components[0], components[1] - 1, components[2]);
	}

	static GetISODate(date = new Date()) {
		const day = (date.getDate() < 10 ? "0" : "") + date.getDate();
		const month = (date.getMonth() + 1 < 10 ? "0" : "") + (date.getMonth() + 1);
		const year = date.getFullYear();
		return year + "-" + month + "-" + day;
	}

	static FormatPhoneNumber(phone) {
		if (!phone) return "";
		phone = phone.replaceAll(" ", "");
		return (phone.length > 9 ? phone.substring(0, phone.length - 9) + " " : "") + phone.substring(phone.length - 9, phone.length - 6) + " " + phone.substring(phone.length - 6, phone.length - 3) + " " + phone.substring(phone.length - 3, phone.length);
	}

	static RenderDurationEndTime(startTime, duration, prefix = "") {
		if (!duration) return "";
		const d = new Date();
		d.setHours(parseInt(startTime.substring(0, 5).split(":")[0]), parseInt(startTime.substring(0, 5).split(":")[1]) + parseInt(duration));
		return prefix + ("" + d.getHours()).padStart(2, "0") + ":" + ("" + d.getMinutes()).padStart(2, "0");
	}

	static FormatTimeWithDuration(slot, duration) {
		if (duration == 0) {
			return slot.substring(0, 5);
		}
		let startHour = parseInt(slot.substring(0, 5).split(":")[0]);
		let startMinute = parseInt(slot.substring(0, 5).split(":")[1]);

		let endHour = startHour;
		let endMinute = startMinute + duration;

		while (endMinute >= 60) {
			endMinute -= 60;
			endHour++;
		}

		endHour = endHour % 24;

		if (endHour < 10) endHour = "0" + endHour;
		if (endMinute < 10) endMinute = "0" + endMinute;

		return slot.substring(0, 5) + " " + L10n.__("a") + " " + endHour + ":" + endMinute;
	}

	static FormatDuration(minutes, longFormat) {
		minutes = parseInt(minutes);
		if (minutes < 60) {
			return minutes + (longFormat ? (" " + L10n.__("minutos")) : " min");
		}

		let hours = 0;
		while (minutes >= 60) {
			minutes -= 60;
			hours++;
		}

		if (minutes > 0) {
			return hours + (longFormat ? (" " + L10n.__((hours == 1 ? "hora" : "horas")) + " ") : " h ") + minutes + (longFormat ? (" " + L10n.__(minutes == 1 ? "minuto" : "minutos")) : " min");
		}

		return hours + (longFormat ? (" " + L10n.__((hours == 1 ? "hora" : "horas"))) : " h");
	}

	static GetRandomID(prefix="") {
		return prefix + Math.floor(Math.random() * 1000000000000 + 1000000000000);
	}

    static ParseAmount(amount) {
        const decimalSeparator = L10n.GetDecimalSeparator();
        const thousandsSeparator = L10n.GetThousandsSeparator();
		const bootstrapData = Session.GetCachedBootstrapData();
		const currencySymbol = bootstrapData.currency?.symbol || "€";

        return parseFloat(amount.replace(currencySymbol, "").replaceAll(thousandsSeparator, "").replace(decimalSeparator, ".").trim());
    }

	static FormatAmount(amount, forceDecimals = false, addCurrencySymbol = true, html = false) {
        const decimalSeparator = L10n.GetDecimalSeparator();
        const thousandsSeparator = L10n.GetThousandsSeparator();

		const split = ("" + amount).split(".");
		const bootstrapData = Session.GetCachedBootstrapData();

		const integer = split[0];

		let fraction = 0;
		if (split.length > 1) {
			fraction = split[1].padEnd(2, "0");
		}

		const integerString = "" + integer;
		let formattedInteger = "";

		let j = 0;
		for (let i = integerString.length - 1; i >= 0; i--) {
			if (j > 0 && j % 3 == 0) {
				formattedInteger = thousandsSeparator + formattedInteger;
			}
			formattedInteger = integerString[i] + formattedInteger;

			j++;
		}

		if (html) {
			formattedInteger = '<span class="number">' + formattedInteger + '</span>'
		}

		const placement = bootstrapData.currency?.placement || "after";
		const symbol = bootstrapData.currency?.symbol || "€";

		if (fraction > 0 || forceDecimals) {
			const fractionString = ("" + fraction).padEnd(2, "0");
			if (addCurrencySymbol) {
				if (placement == "before") {
					return symbol + formattedInteger + decimalSeparator + fractionString;
				} else if (placement == "after") {
					return formattedInteger + decimalSeparator + fractionString + " " + symbol;
				}

				return formattedInteger + decimalSeparator + fractionString;
			} else {
				return formattedInteger + decimalSeparator + fractionString;
			}
		} else {
			if (addCurrencySymbol) {
				if (placement == "before") {
					return symbol + formattedInteger;
				} else if (placement == "after") {
					return formattedInteger + " " + symbol;
				}

				return formattedInteger;
			} else {
				return formattedInteger;
			}
		}
	}

	static GenTimeOptions() {
		const d = new Date();
		const output = [];
		d.setHours(0, 0);
		d.setDate(1);
		while (d.getDate() < 2) {
			output.push(("" + d.getHours()).padStart(2, "0") + ":" + ("" + d.getMinutes()).padStart(2, "0"));
			d.setMinutes(d.getMinutes() + 15);
		}
		return output;
	}

	static ValidateEmail(value) {
		// eslint-disable-next-line no-control-regex
		return value.match(/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/);
	}

	static SngNormalize(str) {
		return str.toLowerCase().replaceAll(" ", "-").replaceAll("à", "a").replaceAll("á", "a").replaceAll("è", "e").replaceAll("é", "e").replaceAll("í", "i").replaceAll("ò", "o").replaceAll("ó", "o").replaceAll("ú", "u").replaceAll(/--+/gi, "-");
	}

	static StringCompare(str1, str2) {
		const str1_normalized = Helper.StringNormalize(str1);
		const str2_normalized = Helper.StringNormalize(str2);
		return str1_normalized === str2_normalized;
	}

	static RenderFullTimeSlot(startTime, meanLunchTime, meanDiningTime, event) {
		let hours = parseInt(startTime.split(":")[0]);
		let minutes = parseInt(startTime.split(":")[1]);

		let hoursStr;
		let minutesStr;

		if (minutes < 10) minutesStr = "0" + minutes;
		else minutesStr = "" + minutes;
		if (hours < 10) hoursStr = "0" + hours;
		else hoursStr = "" + hours;
		startTime = hours + ":" + minutes;

		if (meanLunchTime == 0 && meanDiningTime == 0) {
			return startTime;
		}

		minutes = parseInt(minutesStr);
		hours = parseInt(hoursStr);

		if (typeof event !== "undefined" && event.uses_limited_slots == 0) {
			return startTime;
		} else {
			if (hours >= 16) {
				minutes += parseInt(meanDiningTime);
			} else {
				minutes += parseInt(meanLunchTime);
			}

			while (minutes >= 60) {
				minutes -= 60;
				hours++;
			}

			if (minutes < 10) minutesStr = "0" + minutes;
			else minutesStr = "" + minutes;

			return startTime + " a " + hours + ":" + minutes;
		}
	}

	static RenderPriceRating(rating) {
		let output = "";
		for (let i = 0; i < rating; ++i) {
			output += "€";
		}
		return output;
	}

	static RenderTags(tags) {
		if (!tags) return "";
		return tags.join ? tags.join(" · ").toUpperCase() : JSON.parse(tags).join(" · ").toUpperCase();
	}

	static GetCityShortCode(city) {
		switch (city) {
			default:
				return city;
			case "Madrid":
				return "MAD";
			case "València":
				return "VLC";
		}
	}

	static SummarizeText(text, maxLength) {
		if (text.length > maxLength) {
			text = text.substr(0, maxLength - 1) + "…";
		}
		return text;
	}

	static FilterObject(obj, callback) {
		const array = Object.entries(obj);
		const filtered = array.filter(([k, v]) => callback(v, k));
		return Object.fromEntries(filtered);
	}

	static FormatSize(bytes) {
		const kilobyte = 1024;
		const megabyte = kilobyte * 1024;
		const gigabyte = megabyte * 1024;

		if (bytes >= gigabyte) {
			return (bytes / gigabyte).toLocaleString(L10n.GetLanguage(), { maximumSignificantDigits: 2 }) + ' ' + L10n.__("GB");
		} else if (bytes >= megabyte) {
			return (bytes / megabyte).toLocaleString(L10n.GetLanguage(), { maximumSignificantDigits: 2 }) + ' ' + L10n.__("MB");
		} else if (bytes >= kilobyte) {
			return (bytes / kilobyte).toLocaleString(L10n.GetLanguage(), { maximumSignificantDigits: 2 }) + ' ' + L10n.__("KB");
		}

		return bytes + ' bytes';
	}

}
