import { useState, useEffect, useRef } from "react";

import { APIAdminRequest } from "../services/API";
import Bloque from "./AdminBloque";
import Helper from "../services/Helper";
import { StarMeter } from "./Encuesta";
import DoughnutChart from "./estadisticas/DoughnutChart";
import BarChart from "./estadisticas/BarChart";
import HorizontalBarChart from "./estadisticas/HorizontalBarChart";
import LoadingIndicator from "./LoadingIndicator";
import L10n from "../services/Locale";
import MaterialIcon from "./MaterialIcon";

export const Colors = ['#36a2eb', '#4bc0c0', '#ffcd56', '#ff9f40', '#ff6384'];

export function CalendarFilter(props) {
	const [ calendarRange, setCalendarRange ] = useState({ min: null, max: null});
	const [ months, setMonths ] = useState([]);
	const [ dates, setDates ] = useState(props.defaultValue || { from: null, to: null });
	const [ scrolled, setScrolled ] = useState(false);

	const elementID = "calendar-filter-" + Math.floor(Math.random() * 100000000);
	const today = new Date();

	if (props.resetRef) {
		props.resetRef(() => {
			setDates({ from: null, to: null });
		});
	}

	useEffect(() => {
		APIAdminRequest("stats-get-date-range").then(response => {
			setCalendarRange({
				min: Helper.CreateDateCompatible(response.data.min_date),
				max: Helper.CreateDateCompatible(response.data.max_date)
			});
		});

		document.body.classList.add("has-header");

		return () => {
			document.body.classList.remove("has-header");
		};
	}, []);

	const { visible } = props;
	useEffect(() => {
		if (!visible && (dates.from == null || dates.to == null)) {
			if (dates.from == null) setDates({ from: dates.to, to: dates.to });
			if (dates.to == null) setDates({ from: dates.from, to: dates.from });
		}

		if (visible && !scrolled) {
			setTimeout(() => {
				$("#" + elementID).scrollTop($("#" + elementID).find("#month" + today.getFullYear() + "-" + (today.getMonth() + 1)).offset()?.top - 150);
				setTimeout(() => { setScrolled(false); }, 500);
			});
		}
	}, [ visible ]);

	useEffect(() => {
		const months = [];
		if (calendarRange.min && calendarRange.max) {
			calendarRange.max.setMonth(calendarRange.max.getMonth() + 1);
			for (let index = calendarRange.min; index<=calendarRange.max; index.setMonth(index.getMonth()+1)) {
				months.push(new Date(index));
			}
		}
		setMonths(months);
	}, [ calendarRange ]);

	useEffect(() => {
		if (props.onChange) props.onChange(dates);
	}, [ dates ]);

	if (!props.visible || calendarRange.min == null || calendarRange.max == null || months.length == 0) return null;

	const dateTimeFormat = new Intl.DateTimeFormat(L10n.GetLanguage(), { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' });

	return <div className="calendar-filter" id={elementID} onScroll={e => setScrolled(true)}>
		<div className="calendar-scroll">
			{months.map(month => {
				const firstDate = new Date(month.getFullYear(), month.getMonth(), 1);
				const firstDay = firstDate.getDay() == 0 ? 6 : firstDate.getDay() - 1;
				const lastDate = new Date(month.getFullYear(), month.getMonth() + 1, 0);
				const lastDay = 6 - (lastDate.getDay() == 0 ? 6 : lastDate.getDay() - 1);
				const dateParts = dateTimeFormat.formatToParts(firstDate);

				let firstSundayDate = new Date(firstDate);
				while (firstSundayDate.getDay() != 0) {
					firstSundayDate.setDate(firstSundayDate.getDate() + 1);
				}
				const firstSunday = firstSundayDate.getDate();

				return <div key={"month" + Helper.GetISODate(month)} id={"month" + month.getFullYear() + "-" + (month.getMonth() + 1)}><div className="month-header">{Helper.UCFirst(dateParts[4].value) + " " + firstDate.getFullYear()}</div><div className="month">
					<div className="day-header">{L10n.__("Lu")}</div>
					<div className="day-header">{L10n.__("Ma")}</div>
					<div className="day-header">{L10n.__("Mi")}</div>
					<div className="day-header">{L10n.__("Ju")}</div>
					<div className="day-header">{L10n.__("Vi")}</div>
					<div className="day-header">{L10n.__("Sa")}</div>
					<div className="day-header">{L10n.__("Do")}</div>
					{Array.from(Array(firstDay), (v,i) => i).map(_ => {
						return <div className="dummy" key={"dummy"+_}></div>;
					})}
					{Array.from(Array(lastDate.getDate()), (v,i) => i + 1).map(dateNum => {
						const dayISODate = `${month.getFullYear()}-${Helper.PadStart(month.getMonth()+1, 2, "0")}-${Helper.PadStart(dateNum, 2, "0")}`;
						const startISODate = dates.from;
						const endISODate = dates.to;
						const date = new Date(month.getFullYear(), month.getMonth(), dateNum);
						
						return <div className={"day" + (dateNum <= firstSunday ? " first-week" : "") + (dateNum == lastDate.getDate() ? " last" : "") + (date.getDay() == 0 ? " sunday" : "") + (dayISODate == startISODate ? " start-date" : "") + (dayISODate == endISODate ? " end-date" : "") + ((dayISODate > startISODate && dayISODate < endISODate) ? " in-between-date" : "")} onClick={e => {
							const n = {...dates};
							if (dates.from == null || (dates.from != null && dates.to != null && dates.from != dates.to)) {
								n.from = dayISODate;
								n.to = dayISODate;
							} else {
								if (dayISODate < dates.from) {
									n.to = n.from;
									n.from = dayISODate;
								} else {
									n.to = dayISODate;
								}
							}
							setDates(n);
						}}>{dateNum}</div>;
					})}
					{Array.from(Array(lastDay), (v,i) => i).map(_ => {
						return <div className="dummy end" key={"dummy"+_}></div>;
					})}
				</div></div>;
			})}
		</div>
	</div>;
}

export default function AdminEstadisticas(props) {
	const [ types, setTypes ] = useState([]);
	const [ ratiosByType, setRatiosByType ] = useState([]);
	const [ kpis, setKpis ] = useState();
	const [ ratiosByDay, setRatiosByDay ] = useState([]);
	const [ ratiosByCountry, setRatiosByCountry ] = useState([]);
    const [ surveyData, setSurveyData ] = useState(null);
	const [ loadingInProgress, setLoadingInProgress ] = useState(true);
	const [ showCalendarFilter, setShowCalendarFilter ] = useState(false);
	const [ calendarFilterDates, setCalendarFilterDates ] = useState({from: null, to: null});
	const [ prevCalendarFilterDates, setPrevCalendarFilterDates ] = useState({from: null, to: null});
	const [ dateFilterChanging, setDateFilterChanging ] = useState(false);

	const calendarFilterRef = useRef();
	const dateChangeRef = useRef();

    useEffect(() => {
        if (!types || !types.length) return;
        APIAdminRequest("surveys-get-venue-types-data", { venue_type_ids: types.map(t => t.id).join(","), date_from: calendarFilterDates.from || "", date_to: calendarFilterDates.to || "" }).then(response => {
            setSurveyData(response.data);
        });
    }, [ types, calendarFilterDates ]);

	useEffect(() => {
		if (
			!types.length ||
			(prevCalendarFilterDates.from != calendarFilterDates.from &&
			prevCalendarFilterDates.to != calendarFilterDates.to &&
			(calendarFilterDates.from == null && calendarFilterDates.to == null) ||
			(calendarFilterDates.from != null && calendarFilterDates.to != null))
		) {
			setDateFilterChanging(true);
			setPrevCalendarFilterDates({...calendarFilterDates});

			Promise.all([
				APIAdminRequest("stats-get-types", { date_from: calendarFilterDates.from || "", date_to: calendarFilterDates.to || "" }),
				APIAdminRequest("stats-get-ratio-by-type", { date_from: calendarFilterDates.from || "", date_to: calendarFilterDates.to || "" }),
				APIAdminRequest("stats-get-kpi", { date_from: calendarFilterDates.from || "", date_to: calendarFilterDates.to || "" }),
				APIAdminRequest("stats-get-day-ratio-by-type", { date_from: calendarFilterDates.from || "", date_to: calendarFilterDates.to || "" }),
				APIAdminRequest("stats-get-country-ratio-by-type", { date_from: calendarFilterDates.from || "", date_to: calendarFilterDates.to || "" }),
			]).then(([ types, ratioByType, KPI, dayRatios, countryRatios ]) => {
				setTypes(types.data);
				setRatiosByType(ratioByType.data);
				setKpis(KPI.data);
				setRatiosByDay(dayRatios.data);
				setRatiosByCountry(countryRatios.data);
				setLoadingInProgress(false);
				setDateFilterChanging(false);
			});
		}
	}, [ calendarFilterDates ]);

	useEffect(() => {
		document.body.classList.add("bg-grey");

		const onClick = e => {
			if($(e.target).closest(".calendar-filter, .calendar-filter-toggle").length == 0) {
				setShowCalendarFilter(false);
			}
		};

		document.body.addEventListener("click", onClick);

		return () => {
			document.body.classList.remove("bg-grey");
			document.body.removeEventListener("click", onClick);
		};
	}, []);

	let mediaEncuestas = [];
	if (surveyData && types) {
		types.forEach((type, typeIndex) => {
			let itemCount = 0;
			mediaEncuestas[typeIndex] = 0;
			if (surveyData[type.id] && surveyData[type.id].length) {
				surveyData[type.id].forEach(item => {
					if (item.value > 0) {
						mediaEncuestas[typeIndex] += item.value;
						itemCount++;
					}
				});
			}
			mediaEncuestas[typeIndex] /= itemCount;

			if (isNaN(mediaEncuestas[typeIndex])) mediaEncuestas[typeIndex] = 0;
		});
	}

	if (loadingInProgress) return <LoadingIndicator />;
	
	return (
		<div className="admin-page admin-estadisticas">
			{dateFilterChanging && <LoadingIndicator style={{marginLeft: $(".sidebar").outerWidth(true)/2}} />}
			<div className="header">
				<CalendarFilter visible={showCalendarFilter} onChange={dates => {
					clearTimeout(dateChangeRef.current);
					dateChangeRef.current = setTimeout(() => {
						setCalendarFilterDates(dates);
					}, 1000);
				}} resetRef={reset => {
					calendarFilterRef.current = { reset };
				}} defaultvalue={calendarFilterDates} />
				<MaterialIcon name="calendar_month" tooltip={L10n.__("Filtar por fechas")} className="calendar-filter-toggle" onClick={e => {
					setShowCalendarFilter(!showCalendarFilter);
				}} />
				{calendarFilterDates.from != null && <div className="dates-display"><span>{Helper.FormatISODate(calendarFilterDates.from, Helper.DATE_VERBOSE_SHORT)}&nbsp;</span>{calendarFilterDates.from != calendarFilterDates.to && <span>- {Helper.FormatISODate(calendarFilterDates.to, Helper.DATE_VERBOSE_SHORT)}</span>} <MaterialIcon className="reset-dates" name="restart_alt" onClick={e => {
					calendarFilterRef.current.reset();
					setCalendarFilterDates({ from: null, to: null });
				}} /></div>}
			</div>
			<Bloque className="columnas" style={{opacity: dateFilterChanging ? 0.5 : 1, transition: "opacity 500ms"}}>
				<div className="columna grafica">
					<h2>{L10n.__("Reservas por tipo de negocio")}</h2>
                    <DoughnutChart types={types} ratios={ratiosByType} />
					{ratiosByType.length == 0 && <div className="no-data-caption">{L10n.__("Sin datos")}</div>}
				</div>

				<div className="columna">
					<h2>{L10n.__("Reservas por día de la semana")}</h2>
                    <BarChart types={types} ratios={ratiosByDay} displayCount={true} />
					{Object.values(ratiosByDay).filter(d => d.ratios.length > 0).length == 0 && <div className="no-data-caption">{L10n.__("Sin datos")}</div>}
				</div>
			</Bloque>
			<Bloque className="columnas" style={{opacity: dateFilterChanging ? 0.5 : 1, transition: "opacity 500ms"}}>
				<div className="columna">
					<h2>{L10n.__("Indicadores de rendimiento")}</h2>
					<div className="kpi-container">
						<div className="item">
							<div className="value">{Helper.FormatAmount(Math.floor(kpis.averageAmountPerUser / 100))}</div>
							<div className="caption">{L10n.__("Tícket medio por persona")}</div>
						</div>
						<div className="item">
							<div className="value">{Helper.FormatAmount(Math.floor(kpis.averageAmountPerOrder / 100))}</div>
							<div className="caption">{L10n.__("Tícket medio por pedido")}</div>
						</div>
						<div className="item">
							<div className="value">{kpis.totalOrderCount}</div>
							<div className="caption">{L10n.__("Reservas totales")}</div>
						</div>
						<div className="item">
							<div className="value">{Math.round((kpis.recurrencia * 100 + Number.EPSILON) * 100 / 100)}%</div>
							<div className="caption">{L10n.__("Recurrencia")}</div>
						</div>
						<div className="item">
							<div className="value">{Math.round((kpis.upsellingRatio * 100 + Number.EPSILON) * 100 / 100)}%</div>
							<div className="caption">{L10n.__("Conversión upselling")}</div>
						</div>
						<div className="item">
							<div className="value">{Math.round((kpis.crossSellingRatio * 100 + Number.EPSILON) * 100 / 100)}%</div>
							<div className="caption">{L10n.__("Conversión cross-selling")}</div>
						</div>
					</div>
				</div>

				<div className="columna grafica">
					<h2>{L10n.__("Porcentaje de reservas por país")}</h2>
					<div className="country-list">
						{[...ratiosByCountry].sort((a, b) => a.name < b.name ? -1 : (b.name < a.name ? 1 : 0)).map(country => {
							return <div className="country" key={country.name}>
								<div className="country-name">{country.name}</div>
								<span className="country-count" style={{ width: "max(" + Math.floor(100 - 100 * country.ratio) + "%, 75px)" }}>{country.total + " " + L10n.__("reservas")}</span>
								<div className="country-bar" style={{ width: Math.floor(100 * country.ratio) + "%" }}><HorizontalBarChart types={types} ratios={country.types} /></div>
							</div>;
						})}
					</div>
					{ratiosByCountry.length == 0 && <div className="no-data-caption">{L10n.__("Sin datos")}</div>}
				</div>
			</Bloque>
            {surveyData && types && <Bloque className="encuestas columnas" columnCount={1} style={{paddingTop: 75, position: "relative"}}>
				<h2 style={{position: "absolute", left: 25, top: 25}}>{L10n.__("Valoración global")}</h2>
                {types.map((type, typeIndex) => {
                    if (!surveyData[type.id] || !surveyData[type.id].length) return null;
                    return <div className="columna" key={"type-reviews-"+typeIndex}>
                        <h3>{Helper.UCFirst(type.plural)}</h3>
                        {surveyData[type.id].map((item, index) => {
                            return <div className="category" key={"survey-data-category" + index}>
                                <h3>{item.text}</h3>
                                <StarMeter defaultValue={item.value} />
								<div className="numeric-value">{parseFloat(item.value.toFixed(1)).toLocaleString(L10n.GetLocale())}</div>
                            </div>;
                        })}
						<div className="category mean">
							<h3>{L10n.__("Media")}</h3>
							<StarMeter defaultValue={mediaEncuestas[typeIndex]} />
							<div className="numeric-value">{parseFloat(mediaEncuestas[typeIndex].toFixed(1)).toLocaleString(L10n.GetLocale())}</div>
						</div>
                    </div>
                })}
				{types.length == 0 && <div className="no-data-caption">{L10n.__("Sin datos")}</div>}
            </Bloque>}
		</div>
	);
}
