import Helper from "./Helper";
import CarouselEventos from "../components/CarouselEventos";
import L10n from "./Locale";
import MaterialIcon from "../components/MaterialIcon";
import { SubscriptionsContext } from "../context/SubscriptionsContext";
import { useContext } from "react";

export default class SearchResultsProvider {
	_results = [];
	_elements = [];
	_searchParameters = { ciudad: "", fecha: "", pax: "" };
	_currentMainType = "";
	_currentOtherType = "";
	_mainTypeOffset = 0;
	_adminMode = false;
	_adminRefreshCallback = null;
	_lastTitleRendered = "";
	_onChangeTypeInfo = null;
	_showSubscriptionFee = false;
	_showAveragePrice = true;
	_linkToSubscriptionsOnlyPage = false;
	_subscriptionsContext = null;
	_selectedSubscriptionType = null;

	constructor(elementList, elementsPerRow = 5) {
		this.elementsPerRow = elementsPerRow;
		this.setElementList(elementList);
	}

	setSelectedSubscriptionType(subscription) {
		this._selectedSubscriptionType = subscription;
	}

	setSubscriptionsContext(context) {
		this._subscriptionsContext = context;
	}

	setLinkToSubscriptionsOnlyPage(set) {
		this._linkToSubscriptionsOnlyPage = set;
	}

	setShowSubscriptionFee(show) {
		this._showSubscriptionFee = show;
	}

	setShowAveragePrice(show) {
		this._showAveragePrice = show;
	}

	setOnChangeTypeInfo(cb) {
		this._onChangeTypeInfo = cb;
	}

	setAdminRefreshCallback(callback) {
		this._adminRefreshCallback = callback;
	}

	setAdminMode(adminMode) {
		this._adminMode = adminMode;
	}

	setElementList(elementList) {
		this._elements = [...elementList];
		this.search(this._searchParameters.ciudad, this._searchParameters.fecha, this._searchParameters.pax);
	}

	resetTitleRendering() {
		this._lastTitleRendered = "";
		return true;
	}

	search(ciudad = "", fecha = "", pax = 1) {
		this._results = this.getFilteredElements(ciudad, fecha, pax);
		this._searchParameters = { ciudad, fecha, pax };
		this.resetRenderingOffsets();

		return this._results;
	}

	getFilteredElements(ciudad, fecha, pax) {
		let results = [...this._elements];

		if (ciudad != "") {
			results = results.filter((negocio) => negocio.city == ciudad);
		}

		if (fecha != "") {
			results = results.filter((negocio) => negocio.events.reduce((acc, v) => acc.concat(v.availability), []).filter((row) => row.slot.indexOf(Helper.GetISODate(fecha)) != -1).length > 0);
		}

		const effectivePax = Math.max(1, pax);

		results = results.filter((negocio) => {
			return negocio.events.filter(event => event.loadAlways).length > 0 || negocio.events.reduce((acc, v) => acc.concat(v.availability), []).filter((row) => {
				return row?.pax >= effectivePax;
			}).length > 0;
		});
		results = results.filter(negocio => negocio.events.filter(event => event.enabled).length > 0);

		if (this._subscriptionsContext && this._subscriptionsContext.subscriptionsEnabled) {
			results = results.filter(negocio => {
				for (let userSubscription of this._subscriptionsContext.userSubscriptions || []) {
					for (let event of negocio.events) {
						if (userSubscription.events?.filter(e => e.id == event.id).length) {
							if (effectivePax <= userSubscription.pax) return true;
						}
					}
				}
				return false;
			});
		}

		return results.sort((a, b) => {
            if (a.tipo.order < b.tipo.order && b.tipo.main == a.tipo.main) return -1;
            if (a.tipo.order > b.tipo.order && b.tipo.main == a.tipo.main) return 1;
            if (a.tipo.main && !b.tipo.main) return -1;
            if (!a.tipo.main && b.tipo.main) return 1;
            if (a.order < b.order) return -1;
            if (a.order > b.order) return 1;
            return 0;
        });
	}

	resetRenderingOffsets() {
		if (this._results.length == 0 || typeof this._results[0].tipo != "object") {
			return;
		}

		this._mainTypeOffset = 0;
		this._currentMainType = this._results.filter((result) => parseInt(result.tipo.main) == 1)[0]?.tipo?.id;
		this._currentOtherType = this._results.filter((result) => parseInt(result.tipo.main) != 1)[0]?.tipo?.id;
	}

	_getMainResultsForRendering(maxCount, consumeResults = false) {
		if (!this._currentMainType) {
			return [];
		}

		const currentTypeResults = this._results.filter((result) => parseInt(result.tipo.main) == 1 && result.tipo.id == this._currentMainType);
		const results = currentTypeResults.slice(this._mainTypeOffset, this._mainTypeOffset + maxCount).filter(negocio => negocio.events.filter(event => event.enabled).length > 0);

		if (consumeResults) {
			this._mainTypeOffset += maxCount;

			if (this._mainTypeOffset >= currentTypeResults.length) {
				const mainTypesList = this._results.map((result) => (parseInt(result.tipo.main) == 1 ? result.tipo.id : null)).filter((v, i, a) => v && a.indexOf(v) == i);
				const currentIndex = mainTypesList.indexOf(this._currentMainType);

				if (currentIndex >= 0 && currentIndex < mainTypesList.length) {
					this._currentMainType = mainTypesList[currentIndex + 1];
					this._mainTypeOffset = 0;
				} else {
					this._currentMainType = null;
				}
			}
		}
		
        return results;
	}

	moveType(type, offset) {
		if (this._onChangeTypeInfo) {
			const newType = { ...type };
			newType.previousOrder = newType.order;
			newType.order += offset;
			this._onChangeTypeInfo(newType);
		}
	}

	setTypeMain(type, main) {
		if (this._onChangeTypeInfo) {
			const newType = { ...type };
			newType.main = main;
			this._onChangeTypeInfo(newType);
		}
	}

	renderMainRow(maxCount = 10000) {
		const results = this._getMainResultsForRendering(maxCount, true);
		if (results.length == 0) {
			return null;
		}

		const currentTipo = results[0].tipo;
		const lastTitleRendered = this._lastTitleRendered;
		this._lastTitleRendered = currentTipo.plural;
		const isUniqueType = this._results.filter((v, i) => this._results.findIndex(r => r.tipo.id == v.tipo.id) == i).length == 1;
		const subscriptionsContext = useContext(SubscriptionsContext);

		return (
			<div className="container search-results-container">
				<div className="row">
					<div className="col">
						{!isUniqueType && lastTitleRendered != currentTipo.plural && <h2 className="titulo-bodegas">{Helper.UCFirst(currentTipo.plural)}</h2>}
						<div className="bodegas-container">
							<div className="carrusel-main-eventos" style={{ gridTemplateColumns: "repeat(" + this.elementsPerRow + ", " + this.elementsPerRow + "fr)", width: Helper.IsResponsive() ? "calc(" + results.length * 100 + "vw - " + ((results.length > 1 ? 30 : 0) + results.length * 25) + "px)" : "auto" }}>
								{results.map((negocio, idx) => {
									let link = "/venue/" + negocio.slug + (this._linkToSubscriptionsOnlyPage ? "/suscripciones" : "");

									const skeleton = negocio.events.filter(e => e.loadAlways).length > 0;

									let subscriptionPrice = -1;
									
									if (subscriptionsContext.subscriptionsEnabled) {
										if (this._selectedSubscriptionType?.pack == 1) {
											subscriptionPrice = this._selectedSubscriptionType.price;
										} else {
											const minSubscriptionFee = negocio.events.reduce((min, e) => e.subscription_data.price == -1 ? min : Math.min(min, e.subscription_data.price), Infinity);
											subscriptionPrice = subscriptionsContext.userSubscriptions?.filter(s => s.events?.filter(e => e.negocio_id == negocio.id).length > 0).length == 0 ? minSubscriptionFee : -1;
										}
									}

									let averageRating = 0;
									let count = 0;

									if (negocio.show_ratings) {
										negocio.average_rating?.forEach(rating => {
											if (rating.value > 0) {
												averageRating += rating.value;
												count++;
											}
										});

										if (count > 0) {
											averageRating /= count;
										}
									}

									const iconos_detalle = typeof negocio.iconos_detalle == "object" ? negocio.iconos_detalle : JSON.parse(negocio.iconos_detalle || "[]");
									const iconos = typeof negocio.iconos == "object" ? negocio.iconos : JSON.parse(negocio.iconos || "[]");
                                    let outOfStock = this._showSubscriptionFee && (negocio.events[0]?.subscription_type.pack == 1 ? negocio.events[0]?.subscription_type.stock == 0 : negocio.events.reduce((stock, ev) => Math.max(stock, ev.subscription_data.stock), -1) == 0);

                                    if (subscriptionsContext.subscriptionsEnabled) {
                                        if (this._selectedSubscriptionType.pack == 1) {
                                            if (subscriptionsContext.userSubscriptions.findIndex(t => t.id == this._selectedSubscriptionType.id) != -1) {
                                                outOfStock = false;
                                            }
                                        } else {
                                            if (subscriptionsContext.userSubscriptions?.filter(sub => sub.events.filter(subev => negocio.events.filter(ev => ev.id == subev.id).length > 0).length > 0).length > 0) {
                                                outOfStock = false;
                                            }
                                        }
                                    }

									return (
									<div className={"carrusel-main-evento cover-zoom-listener" + (skeleton ? " skeleton" : "")} key={negocio.image_url + "-" + idx} onClick={e => {
											if (skeleton) return;
												 location.href = link;
											 }}>
											 <div className="image-container cover-zoom" style={{ height: 220 + (4 - this.elementsPerRow) * 15, backgroundImage: skeleton ? null : "url(" + (negocio.image_url[0] == "/" ? negocio.image_url : "/static/images/eventos/" + negocio.image_url) + ")" }}>
												{iconos && (
													<div className="icons">
														{iconos.map((icono, idx) => {
															return (
																<div
																	style={{ position: "relative" }}
																	onClick={(e) => {
																		e.preventDefault();
																		e.stopPropagation();
																		return false;
																	}}
																	key={idx}
																	className="has-tooltip"
																	data-tooltip={icono.description}>
                                                                    <img src={icono.image_url} className="icon-image" />
																</div>
															);
														})}
													</div>
												)}
                                                 {subscriptionsContext?.subscriptionsEnabled && outOfStock && <div className="out-of-stock">{L10n.__("Suscripciones completas")}</div>}
											</div>
											<div className="details">
												<div className="name">{negocio.name}</div>
												<div className="city">{negocio.city}</div>
												<div className="mid">
													{iconos_detalle?.map((icono, iconoIdx) => {
														return <div
															style={{ position: "relative" }}
															onClick={(e) => {
																e.preventDefault();
																e.stopPropagation();
																return false;
															}}
															key={"icono-negocio-"+iconoIdx}
															className="has-tooltip"
															data-tooltip={icono.titulo}
															>
															<img src={icono.image_url} className="icon-image" />
														</div>;
													})}
												</div>
												<div className="lower">
													{!skeleton && this._showAveragePrice && negocio.average_price != -1 && (
														<div className="minimum-price">
															<div>
																{L10n.__("Precio medio")} <span dangerouslySetInnerHTML={{ __html: Helper.FormatAmount(Math.floor(negocio.average_price / 100), false, true, true) }} />
															</div>
														</div>
													)}
													{!skeleton && this._showSubscriptionFee && subscriptionsContext?.subscriptionsEnabled && subscriptionPrice != -1 && (
														<div className="minimum-price">
															<div>
																{L10n.__(this._selectedSubscriptionType.pack == 1 ? "Suscripción pack" : "Suscríbete desde")} <span dangerouslySetInnerHTML={{ __html: Helper.FormatAmount(Math.floor(subscriptionPrice / 100), false, true, true) }} />{L10n.__("/mes")}
															</div>
														</div>
													)}
													{!skeleton && Math.floor(averageRating * 10) > 0 && <div className="average-rating">
														<MaterialIcon name="star_rate" style={{ fontVariationSettings: '"FILL" 1' }} /> {(Math.floor(averageRating * 10) / 10).toLocaleString(L10n.GetLocale())}
													</div>}
												</div>
											</div>
										</div>
									);
								})}
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}

	_getOtherResultsForRendering(consumeResults = false) {
		if (!this._currentOtherType) {
			return [];
		}

		const results = this._results.filter((result) => parseInt(result.tipo.main) != 1 && result.tipo.id == this._currentOtherType);

		if (consumeResults) {
			const otherTypesList = this._results.map((result) => (parseInt(result.tipo.main) != 1 ? result.tipo.id : null)).filter((v, i, a) => v && i == a.indexOf(v));
			const currentIndex = otherTypesList.indexOf(this._currentOtherType);

			if (currentIndex >= 0 && currentIndex < otherTypesList.length) {
				this._currentOtherType = otherTypesList[currentIndex + 1];
			} else {
				this._currentOtherType = null;
			}
		}

		return results;
	}

	renderOtherRow() {
		const results = this._getOtherResultsForRendering(true);

		if (results.length == 0) {
			return null;
		}

		const otherTipo = results[0].tipo;
		const lastTitleRendered = this._lastTitleRendered;
		this._lastTitleRendered = otherTipo.plural;

		return (
			<div className="banda-otros">
				<div className="container search-results-container">
					<div className="info">
						{lastTitleRendered != otherTipo.plural && <h2 className="titulo-bodegas">{Helper.UCFirst(otherTipo.plural)}</h2>}
						<div>{otherTipo.description}</div>
					</div>
					<CarouselEventos adminMode={this._adminMode} elements={results} />
				</div>
			</div>
		);
	}

	renderDefaultRows(renderMainRows = true, renderOtherRows = true, limit = -1) {
		if (limit == 0) {
			return null;
		}

		return (
			<>
				{renderMainRows && this.renderMainRow(this.elementsPerRow)}
				{renderOtherRows && this.renderOtherRow()}
				{limit != 0 && ((renderMainRows && this._getMainResultsForRendering(this.elementsPerRow).length) || (renderOtherRows && this._getOtherResultsForRendering().length)) > 0 && this.renderDefaultRows(renderMainRows, renderOtherRows, limit - 1)}
			</>
		);
	}

	getResults() {
		return this._results;
	}
}
