import "../../css/home.css";

import React, { useContext, useEffect, useRef, useState } from "react";
import Footer from "./Footer.jsx";
import ImagenFondo from "./ImagenFondo";
import { Content, ContentImage, GetJSON } from "./Content";
import Slider from "./Slider.jsx";
import Events from "../services/Events.jsx";
import Settings from "../services/Settings.js";
import LoadingIndicator from "./LoadingIndicator.jsx";
import WidgetFiltro from "./WidgetFiltro";
import SearchResultsProvider from "../services/SearchResultsProvider.jsx";
import Video from "./Video.jsx";
import L10n from "../services/Locale";
import SubscriptionsService from "../services/Subscriptions";
import { SubscriptionsContext } from "../context/SubscriptionsContext";
import SubscriptionTypesBar from "./SubscriptionTypesBar";
import ContentHelper from "../services/ContentHelper";

export default function Home() {
	const eventLoadQueue = useRef();

	const [homeInformationFirst, setHomeInformationFirst] = useState();
	const [slidesConfig, setSlidesConfig] = useState([]);
	const [filteredResults, setFilteredResults] = useState([]);
	const [searchResultsProvider, _setSearchResultsProvider] = useState(new SearchResultsProvider(filteredResults, 3));
	const [venues, setVenues] = useState([]);
	const [ciudades, setCiudades] = useState([]);
	const [filtersActive, setFiltersActive] = useState(false);
	const [videoPlayed, setVideoPlayed] = useState(false);
	const [loading, setLoading] = useState(true);
	const [ eventsLoading, setEventsLoading ] = useState(true);
	const [ showVideo, setShowVideo ] = useState(null);
	const [ showSlider, setShowSlider ] = useState(true);
	const [ showInformationSection, setShowInformationSection ] = useState(true);
	const [ subscriptionInfoBoxes, setSubscriptionInfoBoxes ] = useState(null);
	const [ selectedSubscription, setSelectedSubcription ] = useState(null);

	const subscriptionsContext = useContext(SubscriptionsContext);
	const [ activeSubscriptionTypeIndex, setActiveSubscriptionTypeIndex ] = useState(-1);

	function toggleVideoPlay() {
		const $video = $(".step-video video");
		if ($video.hasClass("playing")) {
			pauseVideo();
		} else {
			playVideo();
		}
	}

	async function playVideo() {
		const $video = $(".step-video video");
		const video = $video[0];

		if (!video) return;

		$video.off("click").on("click", function (e) {
			if ($video.hasClass("playing")) {
				$video.addClass("forced-pause");
			}
			toggleVideoPlay();
		});

		if (!$video.hasClass("playing")) {
			try {
				await video.play();
				$video.addClass("playing").removeClass("forced-pause");
				setVideoPlayed(true);
			} catch (error) {
				$video.removeClass("playing");
				setVideoPlayed(false);
			}
		}
	}

	function pauseVideo() {
		const video = $(".step-video video")[0];

		if (!video) return;

		video.pause();
		video.classList.remove("playing");
	}

	function onScroll(e) {
		const $video = $(".step-video video");

		if (!$(".step-video").length) {
			$(window).off("scroll", onScroll);
			return;
		}

		const videoTop = $(".step-video").offset().top;
		const videoBottom = videoTop + $(".step-video").height();
		const windowTop = $(window).scrollTop();
		const windowBottom = windowTop + window.innerHeight;

		if (windowBottom > videoTop && videoBottom > windowTop) {
			if (!$video.hasClass("forced-pause")) {
				playVideo();
			}
		} else {
			pauseVideo();
		}
	}

	function renderInformationSection() {
		return (
			<section className="information">
				<div className="columna">
					<ImagenFondo className="celda imagen" slug="home-imagen-paso-1" />
					<div className="celda">
						<Content slug="home-paso-1-titulo" containerClassName="section-subtitle" />
						<h2 className="section-title">
							<Content slug="home-subtitulo-paso-1" />
						</h2>
						<Content slug="home-texto-paso-1" containerClassName="p" />
						<div className="logos">
							<div>
								<ContentImage slug="home-paso-1-logo-1" />
							</div>
							<div>
								<ContentImage slug="home-paso-1-logo-2" />
							</div>
							<div>
								<ContentImage slug="home-paso-1-logo-3" />
							</div>
						</div>
					</div>
				</div>
				<div className="columna">
					<div className="celda">
						<Content slug="home-paso-2-titulo" containerClassName="section-subtitle" />
						<h2 className="section-title">
							<Content slug="home-titulo-paso-2" />
						</h2>
						<Content slug="home-subtitulo-paso-2" containerClassName="p" />
						<Content slug="home-texto-paso-2" containerClassName="p" />
						<div className="logos">
							<div>
								<ContentImage slug="home-paso-2-logo-1" containerClassName="steps-3-logo" />
							</div>
							<div>
								<ContentImage slug="home-paso-2-logo-2" containerClassName="steps-3-logo" />
							</div>
							<div>
								<ContentImage slug="home-paso-2-logo-3" containerClassName="steps-3-logo" />
							</div>
						</div>
						<div className="logos">
							<div>
								<ContentImage slug="home-paso-2-logo-4" containerClassName="steps-3-logo" />
							</div>
							<div>
								<ContentImage slug="home-paso-2-logo-5" containerClassName="steps-3-logo" />
							</div>
							<div>
								<ContentImage slug="home-paso-2-logo-6" containerClassName="steps-3-logo" />
							</div>
						</div>
					</div>
					<ImagenFondo className="celda imagen" slug="home-imagen-paso-2" />
				</div>
				<div className="columna">
					<ImagenFondo className="celda imagen" slug="home-imagen-paso-3" />
					<div className="celda">
						<Content slug="home-paso-3-titulo" containerClassName="section-subtitle" />
						<h2 className="section-title">
							<Content slug="home-titulo-paso-3" />
						</h2>
						<Content slug="home-subtitulo-paso-3" containerClassName="p" />
						<Content slug="home-texto-paso-3" containerClassName="p" />
						<div className="logos">
							<div>
								<ContentImage slug="home-paso-3-logo-1" />
							</div>
							<div>
								<ContentImage slug="home-paso-3-logo-2" />
							</div>
							<div>
								<ContentImage slug="home-paso-3-logo-3" />
							</div>
						</div>
					</div>
				</div>
			</section>
		);
	}

	useEffect(() => {
		if (activeSubscriptionTypeIndex != -1) {
			setEventsLoading(true);
			setSelectedSubcription(subscriptionsContext?.subscriptionTypes[activeSubscriptionTypeIndex]);
		} else {
			setEventsLoading(false);
		}
	}, [ activeSubscriptionTypeIndex ]);

	useEffect(() => {
		if (!subscriptionsContext?.subscriptionsEnabled) return;
		searchResultsProvider.setSelectedSubscriptionType(selectedSubscription);

		if (!selectedSubscription) return;

		SubscriptionsService.SetCartData({
			typeSlug: selectedSubscription.slug,
			typeID: selectedSubscription.id,
			typeMode: selectedSubscription.pack == 1 ? SubscriptionsService.SUBSCRIPTION_MODE_TIPO : SubscriptionsService.SUBSCRIPTION_MODE_EVENTO
		});

		SubscriptionsService.GetEventsForType(selectedSubscription.id).then(venues => {
			Events.GetAvailabilityBulk(venues.reduce((vs, v) => vs.concat(v.events), []).map(e => e.id)).then(eventAvailabilities => {
				eventAvailabilities.forEach(eventAvailability => {
					venues.forEach((venue, venueIndex) => {
						venue.events.forEach((event, eventIndex) => {
							if (event.id == eventAvailability.event) {
								venues[venueIndex].events[eventIndex].availability = eventAvailability.availability;
								venues[venueIndex].events[eventIndex].calendar = eventAvailability.calendar;
								venues[venueIndex].events[eventIndex].loadAlways = false;
							}
						});
					});
				});

				setTimeout(() => {
					setEventsLoading(false);
				}, 150);
				
				setVenues(venues);
				searchResultsProvider.setElementList(venues);
				searchResultsProvider.setShowSubscriptionFee(true);
				searchResultsProvider.setShowAveragePrice(false);
				setLoading(false);
			});
		});
	}, [ selectedSubscription ]);

	useEffect(() => {
		if (
			subscriptionsContext.subscriptionsEnabled &&
			subscriptionsContext.subscriptionTypes?.length > 0
		) {
			setHomeInformationFirst(false);
			setShowVideo(false);
			setShowSlider(false);
			setShowInformationSection(false);
			setActiveSubscriptionTypeIndex(0);
		}

		if (subscriptionsContext.subscriptionsEnabled) {
			ContentHelper.GetJSON("subscription-home-info-boxes").then(content => {
				setSubscriptionInfoBoxes(content);
			});
		}

		if (subscriptionsContext.subscriptionsEnabled === false) {
			setLoading(true);

			Promise.all([
				Settings.Get("SHOW_HOME_VIDEO", false),
				Settings.Get("HOME_INFORMATION_FIRST", 0),
				GetJSON("configuracion-slides"),
				Events.GetCities(),
				SubscriptionsService.IsEnabled()
			]).then(([showVideo, homeInformationFirst, slidesJSON, cities, subscriptionsContext]) => {
				setShowVideo(showVideo == 1);
				setHomeInformationFirst(homeInformationFirst == 1);
				setSlidesConfig(slidesJSON);
				setCiudades(cities);
				
				!subscriptionsContext.subscriptionsEnabled && Events.GetAll().then(venues => {
					eventLoadQueue.current = 0;
					for (let i=0; i<venues.length; i++) {
						eventLoadQueue.current += venues[i].events.length;
						venues[i].events.forEach(event => {
							event.availability = [];
							event.calendar = {};
							event.loadAlways = true;

							Events.GetAvailability(event.id).then(response => {
								event.availability = response.availability;
								event.calendar = response.calendar;
								event.loadAlways = false;

								eventLoadQueue.current--;
								if (eventLoadQueue.current <= 0) {
									setVenues(venues);
									searchResultsProvider.setElementList(venues);
									searchResultsProvider.setShowAveragePrice(!subscriptionsContext.subscriptionsEnabled);
									searchResultsProvider.setShowSubscriptionFee(false);
									setLoading(false);
									setEventsLoading(false);
								}
							});
						});
					}
				});
			});

			$(function ($) {
				$(window).on("scroll", onScroll);
			});

			return () => {
				$(window).off("scroll", onScroll);
			};
		}
	}, [ subscriptionsContext ]);

	if (loading) return <LoadingIndicator />;

	searchResultsProvider.resetRenderingOffsets();
	searchResultsProvider.resetTitleRendering();

	const showSubscribeButton = selectedSubscription?.pack == 1 && subscriptionsContext.userSubscriptions.findIndex(t => t.id == selectedSubscription?.id) == -1;
	const showSubscriptionInfo = true;

	return (
		<div className={"home" + (homeInformationFirst ? " information-first" : "")}>
			{showSlider && <Slider
				style={{
					opacity: slidesConfig.length ? 1 : 0
				}}
				effect="fade"
				delay={5000}
				slides={slidesConfig}
			/>}

			{false && (subscriptionsContext?.subscriptionsEnabled || !homeInformationFirst) && venues?.length > 0 && (
				<WidgetFiltro
					ciudades={ciudades}
					elements={venues}
					resultsProvider={searchResultsProvider}
					onFiltered={(filtered, filtersActive) => {
						setFilteredResults(filtered);
						setFiltersActive(filtersActive);
					}}
				/>
			)}

			{subscriptionsContext?.subscriptionsEnabled && subscriptionsContext?.subscriptionTypes?.length > 0 && <SubscriptionTypesBar onChange={idx => {
				setEventsLoading(true);
				setTimeout(() => {
					setActiveSubscriptionTypeIndex(idx);
				}, 250);
			}} />}

			{showInformationSection && homeInformationFirst && filtersActive == false && renderInformationSection()}

			{subscriptionsContext?.subscriptionsEnabled && selectedSubscription && <div style={{ transition: "opacity 300ms", opacity: eventsLoading ? 0 : 1 }}>
				{showSubscriptionInfo && <div className={"subscription-description" + (showSubscribeButton ? " has-button" : "")}>
					<div>
						{showSubscribeButton && <a href={"/suscribete/t/" + selectedSubscription.slug} className="cta btn btn-small">{L10n.__("+ Info")}</a>}
						<div>{selectedSubscription.short_description}</div>
					</div>
				</div>}
				<h2 className="selected-subscription-name">{selectedSubscription.name}</h2>
			</div>}

			<section className="top-list bg-offwhite" style={{ opacity: eventsLoading ? 0 : 1 }}>
				{searchResultsProvider.getResults().length == 0 && !eventsLoading && <div className="no-results">{L10n.__("No se ha encontrado disponibilidad con esta combinación.")}</div>}
				{(filtersActive || (subscriptionsContext?.subscriptionsEnabled && selectedSubscription)) && searchResultsProvider.renderDefaultRows(true, false, 100)}
				{!subscriptionsContext?.subscriptionsEnabled && searchResultsProvider.renderDefaultRows(!filtersActive, true, 2)}
			</section>

			{showInformationSection && !homeInformationFirst && filtersActive == false && renderInformationSection()}

			<section className="top-list bg-offwhite">{searchResultsProvider.resetTitleRendering() && searchResultsProvider.renderDefaultRows(!filtersActive)}</section>

			{showVideo && <div className="row step bg-offgrey step-video">
				<div className="steps-inner">
					<Video slug="video-home" />
					{!videoPlayed && (
						<div
							className="play-button"
							onClick={(e) => {
								e.preventDefault();
								playVideo();
							}}
						/>
					)}
				</div>
			</div>}

			{subscriptionInfoBoxes !== null && <div className="subscription-info-boxes">
				<div className="image" style={{ backgroundImage: "url(" + subscriptionInfoBoxes[0] + ")" }} />
				<div>
					<h2>{subscriptionInfoBoxes[1].title}</h2>
					<div dangerouslySetInnerHTML={{ __html: subscriptionInfoBoxes[1].text }} />
				</div>
			</div>}

			<Footer />
		</div>
	);
}
