import LocalizedStrings from 'react-localization';
import { APIRequest } from './API';
import ContentHelper from './ContentHelper';
import Session from './Session';

export default class L10n {
    static _Strings;
    static _ServerLocale;

    static _Bootstrap() {
        if (!L10n._Strings) {
            L10n._Strings = LocalizedStrings = new LocalizedStrings({
                es: {
                    "Login": "Login",
                    "Registrarse": "Registrarse",
                    "Completar reserva": "Completar reserva",
                    "Panel admin": "Panel admin",
                    "Mi cuenta": "Mi cuenta",
                    "Panel partners": "Panel partners",
                    "Desde": "Desde",
                    "minutos": "minutos",
                    "horas": "horas",
                    "hora": "hora",
                    "minuto": "minuto",
                    "Lugar": "Lugar",
                    "Más filtros": "Más filtros",
                    "Fecha": "Fecha",
                    "persona": "persona",
                    "personas": "personas",
                    "Selecciona un lugar": "Selecciona un lugar",
                    "Selecciona una fecha": "Selecciona una fecha",
                    "Todos": "Todos",
                    "No se ha podido realizar la reserva": "No se ha podido realizar la reserva",
                    "Por favor inténtalo de nuevo o": "Por favor inténtalo de nuevo o",
                    "contacta con nosotros": "contacta con nosotros",
                    "Volver": "Volver",
                    "Tu reserva se ha enviado con éxito": "Tu reserva se ha enviado con éxito",
                    "Tan pronto la confirmemos recibirás un correo de confirmación en": "Tan pronto la confirmemos recibirás un correo de confirmación en",
                    "y en tu WhatsApp.": "y en tu WhatsApp.",
                    "Recuerda comprobar también la carpeta de spam.": "Recuerda comprobar también la carpeta de spam.",
                    "Añadir": "Añadir",
                    "a mis favoritos": "a mis favoritos",
                    "Volver al inicio": "Volver al inicio",
                    "¡Has creado tu cuenta con éxito!": "¡Has creado tu cuenta con éxito!",
                    "Comprueba tu buzón de entrada y carpeta de spam para verificar tu cuenta de correo.": "Comprueba tu buzón de entrada y carpeta de spam para verificar tu cuenta de correo.",
                    "Parece que este enlace no es correcto.": "Parece que este enlace no es correcto.",
                    "¡Tu cuenta ha sido activada!": "¡Tu cuenta ha sido activada!",
                    "entrar": "entrar",
                    "Ha ocurrido un error": "Ha ocurrido un error",
                    "Regístrate": "Regístrate",
                    "Email *": "Email *",
                    "Por favor introduce una dirección de email válida.": "Por favor introduce una dirección de email válida.",
                    "Repetir email *": "Repetir email *",
                    "Los emails no coinciden.": "Los emails no coinciden.",
                    "Contraseña *": "Contraseña *",
                    "La contraseña debe tener al menos 8 caracteres. Una contraseña fuerte debe contener letras, números y símbolos.": "La contraseña debe tener al menos 8 caracteres. Una contraseña fuerte debe contener letras, números y símbolos.",
                    "Repetir contraseña *": "Repetir contraseña *",
                    "Las contraseñas no coinciden.": "Las contraseñas no coinciden.",
                    "Nombre *": "Nombre *",
                    "Apellidos *": "Apellidos *",
                    "Teléfono": "Teléfono",
                    "Código postal": "Código postal",
                    "Introduce un código postal válido.": "Introduce un código postal válido.",
                    "España": "España",
                    "¿Ya tienes una cuenta?": "¿Ya tienes una cuenta?",
                    "Identifícate": "Identifícate",
                    "¿Quieres modificar tu reserva?": "¿Quieres modificar tu reserva?",
                    "Ocultar detalle de la reserva": "Ocultar detalle de la reserva",
                    "Ver detalle de la reserva": "Ver detalle de la reserva",
                    "plazas": "plazas",
                    "Confirmar total": "Confirmar total",
                    "Continuar sin extras": "Continuar sin extras",
                    "Preguntas frecuentes": "Preguntas frecuentes",
                    "UNIRSE A SPOTNOW": "UNIRSE A SPOTNOW",
                    "completa tu reserva": "completa tu reserva",
                    "completa tu suscripción": "completa tu suscripción",
                    "¿Quieres modificarla?": "¿Quieres modificarla?",
                    "Pagar": "Pagar",
                    "Confirmar": "Confirmar",
                    "Debes aceptar los términos de uso y la política de privacidad para continuar.": "Debes aceptar los términos de uso y la política de privacidad para continuar.",
                    "Confirmo que he leído y acepto los": "Confirmo que he leído y acepto los",
                    "términos de uso": "términos de uso",
                    "y la": "y la",
                    "política de privacidad": "política de privacidad",
                    "¡Código descuento aplicado!": "¡Código descuento aplicado!",
                    "¿Tienes un código de descuento?": "¿Tienes un código de descuento?",
                    "Este código no parece válido.": "Este código no parece válido.",
                    "Aplicar": "Aplicar",
                    "¿Quieres modificar tu selección?": "¿Quieres modificar tu selección?",
                    "IVA incluido": "IVA incluido",
                    "Para empresas": "Para empresas",
                    "Reservar ahora": "Reservar ahora",
                    "Contraseña": "Contraseña",
                    "Repetir contraseña": "Repetir contraseña",
                    "Cambiar contraseña": "Cambiar contraseña",
                    "Nombre": "Nombre",
                    "Apellidos": "Apellidos",
                    "Teléfono (opcional)": "Teléfono (opcional)",
                    "Email": "Email",
                    "Guardar": "Guardar",
                    "REPETIR RESERVA": "REPETIR RESERVA",
                    "Extras": "Extras",
                    "COMPLETAR RESERVA": "COMPLETAR RESERVA",
                    "Por favor, introduce una contraseña": "Por favor, introduce una contraseña",
                    "Contraseña cambiada": "Contraseña cambiada",
                    "No se ha podido cambiar la contraseña": "No se ha podido cambiar la contraseña",
                    "Usuario": "Usuario",
                    "Cerrar sesión": "Cerrar sesión",
                    "Códigos descuento": "Códigos descuento",
                    "Código:": "Código:",
                    "Caduca": "Caduca",
                    "No hay descuentos activos": "No hay descuentos activos",
                    "RESERVAR AHORA": "RESERVAR AHORA",
                    "Reservas": "Reservas",
                    "Reservas y disponibilidad": "Reservas y disponibilidad",
                    "No cerrar sesión": "No cerrar sesión",
                    "Entrar": "Entrar",
                    "¿Todavía no tienes una cuenta?": "¿Todavía no tienes una cuenta?",
                    "¿Has olvidado tu contraseña?": "¿Has olvidado tu contraseña?",
                    "Sin nombre": "Sin nombre",
                    "Indica el nombre de la categoría": "Indica el nombre de la categoría",
                    "Sin título": "Sin título",
                    "No se ha encontrado disponibilidad con esta combinación.": "No se ha encontrado disponibilidad con esta combinación.",
                    "Tiempo de uso": "Tiempo de uso",
                    "Spotnow atrae nuevos clientes a tu restaurante": "Spotnow atrae nuevos clientes a tu restaurante",
                    "De forma dinámica para ellos y productiva para ti. Y garantizando cero —No Shows.": "De forma dinámica para ellos y productiva para ti. Y garantizando cero —No Shows.",
                    "Nombre*": "Nombre*",
                    "Email*": "Email*",
                    "Nombre del restaurante*": "Nombre del restaurante*",
                    "Teléfono*": "Teléfono*",
                    "Web del restaurante*": "Web del restaurante*",
                    "Por favor rellena todos los campos obligatorios.": "Por favor rellena todos los campos obligatorios.",
                    "Nombre de la empresa": "Nombre de la empresa",
                    "Detalles del Evento": "Detalles del Evento",
                    "Día/mes/hora estimado *": "Día/mes/hora estimado *",
                    "Número de personas estimado *": "Número de personas estimado *",
                    "Ciudad *": "Ciudad *",
                    "Cosas más concretas": "Cosas más concretas",
                    "Enviar": "Enviar",
                    "Datos": "Datos",
                    "Nombre de la empresa (si es corporativo)": "Nombre de la empresa (si es corporativo)",
                    "Comentarios*": "Comentarios*",
                    "Otros detalles.": "Otros detalles.",
                    "Este campo es obligatorio.": "Este campo es obligatorio.",
                    "FAQ": "FAQ",
                    "CONTACTO": "CONTACTO",
                    "TÉRMINOS Y CONDICIONES": "TÉRMINOS Y CONDICIONES",
                    "PRIVACIDAD": "PRIVACIDAD",
                    "noche": "noche",
                    "noches": "noches",
                    "plaza": "plaza",
                    "Hasta": "Hasta",
                    "Similares": "Similares",
                    "lunes": "lunes",
                    "martes": "martes",
                    "miércoles": "miércoles",
                    "jueves": "jueves",
                    "viernes": "viernes",
                    "sábado": "sábado",
                    "domingo": "domingo",
                    "Lunes": "Lunes",
                    "Martes": "Martes",
                    "Miércoles": "Miércoles",
                    "Jueves": "Jueves",
                    "Viernes": "Viernes",
                    "Sábado": "Sábado",
                    "Domingo": "Domingo",
                    "de": "de",
                    "a": "a",
                    "y de": "y de",
                    "Abierto las 24 horas": "Abierto las 24 horas",
                    "Cerrado": "Cerrado",
                    "y": "y",
                    "reservas": "reservas",
                    "Subtítulo": "Subtítulo",
                    "Invertir colores": "Invertir colores",
                    "Añadir botón": "Añadir botón",
                    "Editar botón": "Editar botón",
                    "Texto del botón": "Texto del botón",
                    "Enlace de destino": "Enlace de destino",
                    "Muchas gracias por contactar con nosotros": "Muchas gracias por contactar con nosotros",
                    "Te responderemos lo antes posible.": "Te responderemos lo antes posible.",
                    "Anterior": "Anterior",
                    "Siguiente": "Siguiente",
                    "Parece que este enlace no es correcto. Por favor inténtalo de nuevo.": "Parece que este enlace no es correcto. Por favor inténtalo de nuevo.",
                    "¡Tu nuevo email ha sido confirmado!": "¡Tu nuevo email ha sido confirmado!",
                    "Hemos enviado un enlace de verificación a tu cuenta de correo electrónico. Recuerda comprobar también la carpeta de ": "Hemos enviado un enlace de verificación a tu cuenta de correo electrónico. Recuerda comprobar también la carpeta de spam.",
                    "No se ha podido cambiar tu contraseña. Por favor comprueba que tus datos sean correctos y vuelve a intentarlo.": "No se ha podido cambiar tu contraseña. Por favor comprueba que tus datos sean correctos y vuelve a intentarlo.",
                    "Tu contraseña se ha cambiado con éxito. Ya puedes utilizarla para entrar.": "Tu contraseña se ha cambiado con éxito. Ya puedes utilizarla para entrar.",
                    "Enero": "Enero",
                    "Febrero": "Febrero",
                    "Marzo": "Marzo",
                    "Abril": "Abril",
                    "Mayo": "Mayo",
                    "Junio": "Junio",
                    "Julio": "Julio",
                    "Agosto": "Agosto",
                    "Septiembre": "Septiembre",
                    "Octubre": "Octubre",
                    "Noviembre": "Noviembre",
                    "Diciembre": "Diciembre",
                    "Ticket reserva:": "Ticket reserva:",
                    "El ticket de reserva es un medio de confirmación de asistencia con el restaurante que se descontará del total de la ": "El ticket de reserva es un medio de confirmación de asistencia con el restaurante que se descontará del total de la cuenta.",
                    "Se descontará en el restaurante": "Se descontará en el restaurante",
                    "No se ha podido conectar con el servidor:": "No se ha podido conectar con el servidor:",
                    "No hay plazas libres para la fecha seleccionada.": "No hay plazas libres para la fecha seleccionada.",
                    "Hora": "Hora",
                    "Seleccionar": "Seleccionar",
                    "L": "L",
                    "M": "M",
                    "X": "X",
                    "J": "J",
                    "V": "V",
                    "S": "S",
                    "D": "D",
                    "Editar horario": "Editar horario",
                    "24 horas": "24 horas",
                    "Horario partido": "Horario partido",
                    "Desactivar horario de tardes": "Desactivar horario de tardes",
                    "Activar horario de tardes": "Activar horario de tardes",
                    "Cancelar": "Cancelar",
                    "Guardar cambios": "Guardar cambios",
                    "Listado de usuarios": "Listado de usuarios",
                    "Activo": "Activo",
                    "CP": "CP",
                    "Partner": "Partner",
                    "Admin": "Admin",
                    "Los cambios no guardados se perderán. ¿Deseas continuar?": "Los cambios no guardados se perderán. ¿Deseas continuar?",
                    "Sí": "Sí",
                    "No": "No",
                    "Contraseña (dejar en blanco para conservar la actual)": "Contraseña (dejar en blanco para conservar la actual)",
                    "Administrador": "Administrador",
                    "Reservas del usuario": "Reservas del usuario",
                    "Comentarios del cliente": "Comentarios del cliente",
                    "Tipo de reserva": "Tipo de reserva",
                    "Notas": "Notas",
                    "¿Seguro que deseas cancelar esta reserva?": "¿Seguro que deseas cancelar esta reserva?",
                    "Cambios guardados con éxito": "Cambios guardados con éxito",
                    "¿Seguro que deseas guardar los cambios?": "¿Seguro que deseas guardar los cambios?",
                    "Guardar reserva": "Guardar reserva",
                    "Estadísticas de usuario": "Estadísticas de usuario",
                    "Reservas por tipo de negocio": "Reservas por tipo de negocio",
                    "Indicadores de rendimiento": "Indicadores de rendimiento",
                    "Tícket medio": "Tícket medio",
                    "Conversión upselling": "Conversión upselling",
                    "Conversión cross-selling": "Conversión cross-selling",
                    "Disponibilidad": "Disponibilidad",
                    "E-mail": "E-mail",
                    "Validada": "Validada",
                    "Crear reserva": "Crear reserva",
                    "Personas": "Personas",
                    "Precio unitario": "Precio unitario",
                    "¿Quieres guardar los cambios?": "¿Quieres guardar los cambios?",
                    "Nueva categoría": "Nueva categoría",
                    "Destacado": "Destacado",
                    "Mover hacia arriba": "Mover hacia arriba",
                    "Mover hacia abajo": "Mover hacia abajo",
                    "Nombre de la categoría": "Nombre de la categoría",
                    "Título de la sección": "Título de la sección",
                    "Borrar categoría": "Borrar categoría",
                    "Este sitio es multilingüe.": "Este sitio es multilingüe.",
                    "Email de contacto": "Email de contacto",
                    "Nombre de la marca": "Nombre de la marca",
                    "Símbolo de divisa": "Símbolo de divisa",
                    "Posición del símbolo": "Posición del símbolo",
                    "Antes": "Antes",
                    "Después": "Después",
                    "Logo": "Logo",
                    "Icono": "Icono",
                    "URL": "URL",
                    "Código de acceso API Facebook": "Código de acceso API Facebook",
                    "ID número de teléfono WhatsApp Business": "ID número de teléfono WhatsApp Business",
                    "Servidor SMTP": "Servidor SMTP",
                    "Puerto": "Puerto",
                    "Usuario SMTP": "Usuario SMTP",
                    "Contraseña SMTP": "Contraseña SMTP",
                    "Teléfono para notificaciones": "Teléfono para notificaciones",
                    "Tag Google Analytics": "Tag Google Analytics",
                    "Clave API Google Passes": "Clave API Google Passes",
                    "Merchant ID": "Merchant ID",
                    "Acquirer BIN": "Acquirer BIN",
                    "ID de terminal": "ID de terminal",
                    "URL TPV": "URL TPV",
                    "Clave secreta": "Clave secreta",
                    "Imagen de encabezado": "Imagen de encabezado",
                    "Preguntas": "Preguntas",
                    "Pregunta": "Pregunta",
                    "Respuesta": "Respuesta",
                    "Borrar pregunta": "Borrar pregunta",
                    "Pregunta sin título": "Pregunta sin título",
                    "Reservas por día de la semana": "Reservas por día de la semana",
                    "Tícket medio por persona": "Tícket medio por persona",
                    "Recurrencia": "Recurrencia",
                    "Número de reservas por país": "Número de reservas por país",
                    "País": "País",
                    "Email de bienvenida": "Email de bienvenida",
                    "Cambio de contraseña": "Cambio de contraseña",
                    "Cambio de email": "Cambio de email",
                    "Introducción al email de nuevo pedido (cliente)": "Introducción al email de nuevo pedido (cliente)",
                    "Texto en el email de nuevo pedido (cliente)": "Texto en el email de nuevo pedido (cliente)",
                    "Introducción al email de nuevo pedido (negocio)": "Introducción al email de nuevo pedido (negocio)",
                    "Texto en el email de nuevo pedido (negocio)": "Texto en el email de nuevo pedido (negocio)",
                    "Introducción al email de nuevo pedido (administrador)": "Introducción al email de nuevo pedido (administrador)",
                    "Texto en el email de nuevo pedido (administrador)": "Texto en el email de nuevo pedido (administrador)",
                    "¿Seguro que quieres guardar los cambios?": "¿Seguro que quieres guardar los cambios?",
                    "Nuevo negocio": "Nuevo negocio",
                    "Borrar negocio": "Borrar negocio",
                    "Administrador/a": "Administrador/a",
                    "Tipo": "Tipo",
                    "Ubicación": "Ubicación",
                    "Dirección": "Dirección",
                    "Ciudad": "Ciudad",
                    "Eventos": "Eventos",
                    "Categoría": "Categoría",
                    "Descripción": "Descripción",
                    "Precio": "Precio",
                    "Imagen": "Imagen",
                    "Iconos": "Iconos",
                    "Etiqueta": "Etiqueta",
                    "Detalles": "Detalles",
                    "Título": "Título",
                    "Listado": "Listado",
                    "Estadísticas": "Estadísticas",
                    "Nuevo evento": "Nuevo evento",
                    "Borrar evento": "Borrar evento",
                    "Programar disponibilidad": "Programar disponibilidad",
                    "Full booking": "Full booking",
                    "Disponibilidad múltiple": "Disponibilidad múltiple",
                    "Cross-selling": "Cross-selling",
                    "Plazas": "Plazas",
                    "Productos": "Productos",
                    "Hora inicio": "Hora inicio",
                    "Hora fin": "Hora fin",
                    "Definir hora de finalización": "Definir hora de finalización",
                    "Añadir horario": "Añadir horario",
                    "Crear": "Crear",
                    "Hay cambios sin guardar. ¿Quieres descartarlos?": "Hay cambios sin guardar. ¿Quieres descartarlos?",
                    "Valor": "Valor",
                    "Código": "Código",
                    "Usos": "Usos",
                    "Caducidad": "Caducidad",
                    "¿Seguro que deseas borrar este código de descuento?": "¿Seguro que deseas borrar este código de descuento?",
                    "Contenidos de portada": "Contenidos de portada",
                    "Formulario de contacto": "Formulario de contacto",
                    "Textos legales": "Textos legales",
                    "Términos y condiciones": "Términos y condiciones",
                    "Política de privacidad": "Política de privacidad",
                    "Página estática": "Página estática",
                    "Códigos de descuento": "Códigos de descuento",
                    "Nuevo código": "Nuevo código",
                    "Importe": "Importe",
                    "Porcentaje": "Porcentaje",
                    "E-mails": "E-mails",
                    "Editar evento": "Editar evento",
                    "Editar negocio": "Editar negocio",
                    "Listado de negocios": "Listado de negocios",
                    "Ajustes generales": "Ajustes generales",
                    "Permisos": "Permisos",
                    "Idiomas": "Idiomas",
                    "Portada": "Portada",
                    "Contacto": "Contacto",
                    "Negocios": "Negocios",
                    "Usuarios": "Usuarios",
                    "General": "General",
                    "Próximas": "Próximas",
                    "Pasadas": "Pasadas",
                    "Canceladas": "Canceladas",
                    "Ver extras": "Ver extras",
                    "Reserva pendiente": "Reserva pendiente",
					"GB": "GB",
					"MB": "MB",
					"KB": "KB",
					"Tamaño máximo de archivo permitido:": "Tamaño máximo de archivo permitido:",
					"El tamaño del archivo supera el máximo permitido.": "El tamaño del archivo supera el máximo permitido.",
					"Proporción:": "Proporción:",
					"Formato:": "Formato:",
					"Tamaño:": "Tamaño:",
					"máx.": "máx.",
					"Desactivar negocio": "Desactivar negocio",
					"Activar negocio": "Activar negocio",
					"Activar evento": "Activar evento",
					"Desactivar evento": "Desactivar evento",
					"¿Seguro que quieres borrar este evento?": "¿Seguro que quieres borrar este evento?",
					"Horario de apertura": "Horario de apertura",
                    "Ciudades": "Ciudades",
                    "Nueva ciudad": "Nueva ciudad",
                    "Borrar ciudad": "Borrar ciudad",
                    "Ciudades y zonas": "Ciudades y zonas",
                    "Información sobre el negocio.": "Información sobre el negocio.",
                    "Activar categoría": "Activar categoría",
                    "Desactivar categoría": "Desactivar categoría",
                    "Descripción del evento.": "Descripción del evento.",
                    "Ofrece reservar en otros tipos de negocio complementarios al seleccionar este evento.": "Ofrece reservar en otros tipos de negocio complementarios al seleccionar este evento.",
                    "Mostrar la selección de extras al seleccionar este evento.": "Mostrar la selección de extras al seleccionar este evento.",
                    "El cliente puede seleccionar más de una fecha consecutiva.": "El cliente puede seleccionar más de una fecha consecutiva.",
                    "Una reserva de este evento conlleva la ocupación de todas las plazas.": "Una reserva de este evento conlleva la ocupación de todas las plazas.",
                    "Lu": "Lu",
                    "Ma": "Ma",
                    "Mi": "Mi",
                    "Ju": "Ju",
                    "Vi": "Vi",
                    "Sa": "Sa",
                    "Do": "Do",
                    "Introduce tu e-mail a continuación y enviaremos un enlace de verificación para que puedas cambiar tu contraseña:": "Introduce tu e-mail a continuación y enviaremos un enlace de verificación para que puedas cambiar tu contraseña:",
                    "No se ha podido enviar tu enlace de recuperación. Por favor comprueba que tus datos sean correctos y vuelve a intentarlo.": "No se ha podido enviar tu enlace de recuperación. Por favor comprueba que tus datos sean correctos y vuelve a intentarlo.",
                    "Encuesta de satisfacción": "Encuesta de satisfacción",
                    "Hemos enviado un enlace de verificación a tu cuenta de correo electrónico. Recuerda comprobar también la carpeta de spam.": "Hemos enviado un enlace de verificación a tu cuenta de correo electrónico. Recuerda comprobar también la carpeta de spam.",
                    "Deberás dar de alta las fechas manualmente en el apartado Disponibilidad": "Deberás dar de alta las fechas manualmente en el apartado Disponibilidad",
                    "Tícket medio por pedido": "Tícket medio por pedido",
                    "Reservas totales": "Reservas totales",
                    "Porcentaje de reservas por país": "Porcentaje de reservas por país",
                    "Valoración global": "Valoración global",
                    "Filtrar por fechas": "Filtrar por fechas",
                    "Precio medio": "Precio medio",
                    "Ordenar por valoración": "Ordenar por valoración",
                    "Ordenar alfabéticamente": "Ordenar alfabéticamente",
                    "Actualizar": "Actualizar",
                    "Valoración del evento": "Valoración del evento",
                    "Media": "Media"
				},
                en: {
                    "Login": "Login",
                    "Registrarse": "Sign up",
                    "Completar reserva": "Complete booking",
                    "Panel admin": "Admin panel",
                    "Mi cuenta": "My account",
                    "Panel partners": "Partners panel",
                    "Desde": "From",
                    "minutos": "minutes",
                    "horas": "hours",
                    "hora": "hour",
                    "minuto": "minute",
                    "Lugar": "Location",
                    "Más filtros": "More filters",
                    "Fecha": "Date",
                    "persona": "person",
                    "personas": "people",
                    "Selecciona un lugar": "Select a location",
                    "Selecciona una fecha": "Select a date",
                    "Todos": "All",
                    "No se ha podido realizar la reserva": "The booking could not be completed",
                    "Por favor inténtalo de nuevo o": "Please try again or",
                    "contacta con nosotros": "contact us",
                    "Volver": "Back",
                    "Tu reserva se ha enviado con éxito": "Your order has been received successfully",
                    "Tan pronto la confirmemos recibirás un correo de confirmación en": "Once we process it, you will receive a confirmation email at",
                    "y en tu WhatsApp.": "and on your WhatsApp.",
                    "Recuerda comprobar también la carpeta de spam.": "Remember to also check your spam folder.",
                    "Añadir": "Add",
                    "a mis favoritos": "to my favorites",
                    "Volver al inicio": "Back to home",
                    "¡Has creado tu cuenta con éxito!": "You have successfully created your account!",
                    "Comprueba tu buzón de entrada y carpeta de spam para verificar tu cuenta de correo.": "Please check your inbox and spam folder to verify your email account.",
                    "Parece que este enlace no es correcto.": "It seems that this link is not correct.",
                    "¡Tu cuenta ha sido activada!": "Your account has been activated!",
                    "entrar": "log in",
                    "Ha ocurrido un error": "An error has occurred",
                    "Regístrate": "Sign up",
                    "Email *": "Email *",
                    "Por favor introduce una dirección de email válida.": "Please enter a valid email address.",
                    "Repetir email *": "Repeat email *",
                    "Los emails no coinciden.": "The emails do not match.",
                    "Contraseña *": "Password *",
                    "La contraseña debe tener al menos 8 caracteres. Una contraseña fuerte debe contener letras, números y símbolos.": "The password must be at least 8 characters long. A strong password should contain letters, numbers, and symbols.",
                    "Repetir contraseña *": "Repeat password *",
                    "Las contraseñas no coinciden.": "The passwords do not match.",
                    "Nombre *": "Name *",
                    "Apellidos *": "Last name *",
                    "Teléfono": "Phone",
                    "Código postal": "Postal code",
                    "Introduce un código postal válido.": "Please enter a valid postal code.",
                    "España": "Spain",
                    "¿Ya tienes una cuenta?": "Already have an account?",
                    "Identifícate": "Log in",
                    "¿Quieres modificar tu reserva?": "Do you want to modify your booking?",
                    "Ocultar detalle de la reserva": "Hide booking details",
                    "Ver detalle de la reserva": "View booking details",
                    "plazas": "slots",
                    "Confirmar total": "Confirm total",
                    "Continuar sin extras": "Continue without extras",
                    "Preguntas frecuentes": "Frequently Asked Questions",
                    "UNIRSE A SPOTNOW": "JOIN SPOTNOW",
                    "completa tu reserva": "complete your booking",
                    "completa tu suscripción": "complete your subscription",
                    "¿Quieres modificarla?": "Do you want to modify it?",
                    "Pagar": "Pay",
                    "Confirmar": "Confirm",
                    "Debes aceptar los términos de uso y la política de privacidad para continuar.": "You must accept the terms of use and privacy policy to continue.",
                    "Confirmo que he leído y acepto los": "I confirm that I have read and accept the",
                    "términos de uso": "terms of use",
                    "y la": "and the",
                    "política de privacidad": "privacy policy",
                    "¡Código descuento aplicado!": "Discount code applied!",
                    "¿Tienes un código de descuento?": "Do you have a discount code?",
                    "Este código no parece válido.": "This code does not seem to be valid.",
                    "Aplicar": "Apply",
                    "¿Quieres modificar tu selección?": "Do you want to modify your selection?",
                    "IVA incluido": "VAT included",
                    "Para empresas": "For companies",
                    "Reservar ahora": "Book now",
                    "Contraseña": "Password",
                    "Repetir contraseña": "Repeat password",
                    "Cambiar contraseña": "Change password",
                    "Nombre": "Name",
                    "Apellidos": "Last name",
                    "Teléfono (opcional)": "Phone (optional)",
                    "Email": "Email",
                    "Guardar": "Save",
                    "REPETIR RESERVA": "REPEAT BOOKING",
                    "Extras": "Extras",
                    "COMPLETAR RESERVA": "COMPLETE BOOKING",
                    "Por favor, introduce una contraseña": "Please enter a password",
                    "Contraseña cambiada": "Password changed",
                    "No se ha podido cambiar la contraseña": "Unable to change password",
                    "Usuario": "User",
                    "Cerrar sesión": "Log out",
                    "Códigos descuento": "Discount codes",
                    "Código:": "Code:",
                    "Caduca": "Expires",
                    "No hay descuentos activos": "No active discounts",
                    "RESERVAR AHORA": "BOOK NOW",
                    "Reservas": "Booking",
                    "Reservas y disponibilidad": "Booking and availability",
                    "No cerrar sesión": "Don't log out",
                    "Entrar": "Log in",
                    "¿Todavía no tienes una cuenta?": "Don't have an account yet?",
                    "¿Has olvidado tu contraseña?": "Forgot your password?",
                    "Sin nombre": "No name",
                    "Indica el nombre de la categoría": "Enter the category name",
                    "Sin título": "No title",
                    "No se ha encontrado disponibilidad con esta combinación.": "No availability found with this combination.",
                    "Tiempo de uso": "Usage time",
                    "Spotnow atrae nuevos clientes a tu restaurante": "Spotnow attracts new customers to your restaurant",
                    "De forma dinámica para ellos y productiva para ti. Y garantizando cero —No Shows.": "Dynamically for them and productive for you. And guaranteeing zero No-Shows.",
                    "Nombre*": "Name*",
                    "Email*": "Email*",
                    "Nombre del restaurante*": "Restaurant name*",
                    "Teléfono*": "Phone number*",
                    "Web del restaurante*": "Restaurant website*",
                    "Por favor rellena todos los campos obligatorios.": "Please fill in all required fields.",
                    "Nombre de la empresa": "Company name",
                    "Detalles del Evento": "Event Details",
                    "Día/mes/hora estimado *": "Estimated day/month/time *",
                    "Número de personas estimado *": "Estimated number of people *",
                    "Ciudad *": "City *",
                    "Cosas más concretas": "More specific details",
                    "Enviar": "Send",
                    "Datos": "Data",
                    "Nombre de la empresa (si es corporativo)": "Company name (if corporate)",
                    "Comentarios*": "Comments*",
                    "Otros detalles.": "Other details.",
                    "Este campo es obligatorio.": "This field is required.",
                    "FAQ": "FAQ",
                    "CONTACTO": "CONTACT",
                    "TÉRMINOS Y CONDICIONES": "TERMS AND CONDITIONS",
                    "PRIVACIDAD": "PRIVACY",
                    "noche": "night",
                    "noches": "nights",
                    "plaza": "seat",
                    "Hasta": "Until",
                    "Similares": "Similar",
                    "lunes": "Monday",
                    "martes": "Tuesday",
                    "miércoles": "Wednesday",
                    "jueves": "Thursday",
                    "viernes": "Friday",
                    "sábado": "Saturday",
                    "domingo": "Sunday",
                    "Lunes": "Monday",
                    "Martes": "Tuesday",
                    "Miércoles": "Wednesday",
                    "Jueves": "Thursday",
                    "Viernes": "Friday",
                    "Sábado": "Saturday",
                    "Domingo": "Sunday",
                    "de": "from",
                    "a": "to",
                    "y de": "and from",
                    "Abierto las 24 horas": "Open 24 hours",
                    "Cerrado": "Closed",
                    "y": "and",
                    "reservas": "reservations",
                    "Subtítulo": "Subtitle",
                    "Invertir colores": "Invert colors",
                    "Añadir botón": "Add button",
                    "Editar botón": "Edit button",
                    "Texto del botón": "Button text",
                    "Enlace de destino": "Destination link",
                    "Muchas gracias por contactar con nosotros": "Thank you for contacting us",
                    "Te responderemos lo antes posible.": "We will respond to you as soon as possible.",
                    "Anterior": "Previous",
                    "Siguiente": "Next",
                    "Parece que este enlace no es correcto. Por favor inténtalo de nuevo.": "It seems that this link is not correct. Please try again.",
                    "¡Tu nuevo email ha sido confirmado!": "Your new email has been confirmed!",
                    "Hemos enviado un enlace de verificación a tu cuenta de correo electrónico. Recuerda comprobar también la carpeta de ": "We have sent a verification link to your email account. Remember to also check the spam folder.",
                    "No se ha podido cambiar tu contraseña. Por favor comprueba que tus datos sean correctos y vuelve a intentarlo.": "Your password could not be changed. Please check that your data is correct and try again.",
                    "Tu contraseña se ha cambiado con éxito. Ya puedes utilizarla para entrar.": "Your password has been successfully changed. You can now use it to log in.",
                    "Enero": "January",
                    "Febrero": "February",
                    "Marzo": "March",
                    "Abril": "April",
                    "Mayo": "May",
                    "Junio": "June",
                    "Julio": "July",
                    "Agosto": "August",
                    "Septiembre": "September",
                    "Octubre": "October",
                    "Noviembre": "November",
                    "Diciembre": "December",
                    "Ticket reserva:": "Reservation ticket:",
                    "El ticket de reserva es un medio de confirmación de asistencia con el restaurante que se descontará del total de la ": "The reservation ticket is a means of confirming attendance with the restaurant, which will be deducted from the total bill.",
                    "Se descontará en el restaurante": "It will be deducted at the restaurant",
                    "No se ha podido conectar con el servidor:": "Could not connect to the server:",
                    "No hay plazas libres para la fecha seleccionada.": "There are no available slots for the selected date.",
                    "Hora": "Time",
                    "Seleccionar": "Select",
                    "L": "M",
                    "M": "T",
                    "X": "W",
                    "J": "T",
                    "V": "F",
                    "S": "S",
                    "D": "S",
                    "Editar horario": "Edit schedule",
                    "24 horas": "24 hours",
                    "Horario partido": "Split schedule",
                    "Desactivar horario de tardes": "Disable afternoon schedule",
                    "Activar horario de tardes": "Activate afternoon schedule",
                    "Cancelar": "Cancel",
                    "Guardar cambios": "Save changes",
                    "Listado de usuarios": "User list",
                    "Activo": "Active",
                    "CP": "ZIP",
                    "Partner": "Partner",
                    "Admin": "Admin",
                    "Los cambios no guardados se perderán. ¿Deseas continuar?": "Unsaved changes will be lost. Do you want to continue?",
                    "Sí": "Yes",
                    "No": "No",
                    "Contraseña (dejar en blanco para conservar la actual)": "Password (leave blank to keep current)",
                    "Administrador": "Administrator",
                    "Reservas del usuario": "User reservations",
                    "Comentarios del cliente": "Customer comments",
                    "Tipo de reserva": "Type of reservation",
                    "Notas": "Notes",
                    "¿Seguro que deseas cancelar esta reserva?": "Are you sure you want to cancel this reservation?",
                    "Cambios guardados con éxito": "Changes saved successfully",
                    "¿Seguro que deseas guardar los cambios?": "Are you sure you want to save the changes?",
                    "Guardar reserva": "Save reservation",
                    "Estadísticas de usuario": "User statistics",
                    "Reservas por tipo de negocio": "Reservations by business type",
                    "Indicadores de rendimiento": "Performance indicators",
                    "Tícket medio": "Average ticket",
                    "Conversión upselling": "Upselling conversion",
                    "Conversión cross-selling": "Cross-selling conversion",
                    "Disponibilidad": "Availability",
                    "E-mail": "Email",
                    "Validada": "Validated",
                    "Crear reserva": "Create reservation",
                    "Personas": "People",
                    "Precio unitario": "Unit price",
                    "¿Quieres guardar los cambios?": "Do you want to save the changes?",
                    "Nueva categoría": "New category",
                    "Destacado": "Featured",
                    "Mover hacia arriba": "Move up",
                    "Mover hacia abajo": "Move down",
                    "Nombre de la categoría": "Category name",
                    "Título de la sección": "Section title",
                    "Borrar categoría": "Delete category",
                    "Este sitio es multilingüe.": "This site is multilingual.",
                    "Email de contacto": "Contact email",
                    "Nombre de la marca": "Brand name",
                    "Símbolo de divisa": "Currency symbol",
                    "Posición del símbolo": "Symbol placement",
                    "Antes": "Before",
                    "Después": "After",
                    "Logo": "Logo",
                    "Icono": "Icon",
                    "URL": "URL",
                    "Código de acceso API Facebook": "Facebook API Access Code",
                    "ID número de teléfono WhatsApp Business": "WhatsApp Business Phone Number ID",
                    "Servidor SMTP": "SMTP Server",
                    "Puerto": "Port",
                    "Usuario SMTP": "SMTP User",
                    "Contraseña SMTP": "SMTP Password",
                    "Teléfono para notificaciones": "Notification phone number",
                    "Tag Google Analytics": "Google Analytics Tag",
                    "Clave API Google Passes": "Google Passes API Key",
                    "Merchant ID": "Merchant ID",
                    "Acquirer BIN": "Acquirer BIN",
                    "ID de terminal": "Terminal ID",
                    "URL TPV": "POS URL",
                    "Clave secreta": "Secret Key",
                    "Imagen de encabezado": "Header image",
                    "Preguntas": "Questions",
                    "Pregunta": "Question",
                    "Respuesta": "Answer",
                    "Borrar pregunta": "Delete question",
                    "Pregunta sin título": "Untitled question",
                    "Reservas por día de la semana": "Reservations by day of the week",
                    "Tícket medio por persona": "Average ticket per person",
                    "Recurrencia": "Recurrence",
                    "Número de reservas por país": "Reservations by country",
                    "País": "Country",
                    "Email de bienvenida": "Welcome email",
                    "Cambio de contraseña": "Password change",
                    "Cambio de email": "Email change",
                    "Introducción al email de nuevo pedido (cliente)": "Introduction to new order email (customer)",
                    "Texto en el email de nuevo pedido (cliente)": "Text in new order email (customer)",
                    "Introducción al email de nuevo pedido (negocio)": "Introduction to new order email (business)",
                    "Texto en el email de nuevo pedido (negocio)": "Text in new order email (business)",
                    "Introducción al email de nuevo pedido (administrador)": "Introduction to new order email (administrator)",
                    "Texto en el email de nuevo pedido (administrador)": "Text in new order email (administrator)",
                    "¿Seguro que quieres guardar los cambios?": "Are you sure you want to save the changes?",
                    "Nuevo negocio": "New business",
                    "Borrar negocio": "Delete business",
                    "Administrador/a": "Administrator",
                    "Tipo": "Type",
                    "Ubicación": "Location",
                    "Dirección": "Address",
                    "Ciudad": "City",
                    "Eventos": "Events",
                    "Categoría": "Category",
                    "Descripción": "Description",
                    "Precio": "Price",
                    "Imagen": "Image",
                    "Iconos": "Icons",
                    "Etiqueta": "Label",
                    "Detalles": "Details",
                    "Título": "Title",
                    "Listado": "List",
                    "Estadísticas": "Statistics",
                    "Nuevo evento": "New event",
                    "Borrar evento": "Delete event",
                    "Programar disponibilidad": "Schedule availability",
                    "Full booking": "Full booking",
                    "Disponibilidad múltiple": "Multiple availability",
                    "Cross-selling": "Cross-selling",
                    "Plazas": "Slots",
                    "Productos": "Products",
                    "Hora inicio": "Start time",
                    "Hora fin": "End time",
                    "Definir hora de finalización": "Set end time",
                    "Añadir horario": "Add schedule",
                    "Crear": "Create",
                    "Hay cambios sin guardar. ¿Quieres descartarlos?": "There are unsaved changes. Do you want to discard them?",
                    "Valor": "Value",
                    "Código": "Code",
                    "Usos": "Uses",
                    "Caducidad": "Expiration",
                    "¿Seguro que deseas borrar este código de descuento?": "Are you sure you want to delete this discount code?",
                    "Contenidos de portada": "Homepage contents",
                    "Formulario de contacto": "Contact form",
                    "Textos legales": "Legal texts",
                    "Términos y condiciones": "Terms and conditions",
                    "Política de privacidad": "Privacy policy",
                    "Página estática": "Static page",
                    "Códigos de descuento": "Discount codes",
                    "Nuevo código": "New code",
                    "Importe": "Amount",
                    "Porcentaje": "Percentage",
                    "E-mails": "Emails",
                    "Editar evento": "Edit event",
                    "Editar negocio": "Edit business",
                    "Listado de negocios": "Business listing",
                    "Ajustes generales": "General settings",
                    "Permisos": "Permissions",
                    "Idiomas": "Languages",
                    "Portada": "Homepage",
                    "Contacto": "Contact",
                    "Negocios": "Businesses",
                    "Usuarios": "Users",
                    "General": "General",
                    "Próximas": "Future",
                    "Pasadas": "Past",
                    "Canceladas": "Cancelled",
                    "Ver extras": "View extras",
                    "Reserva pendiente": "Pending order",
					"GB": "GB",
					"MB": "MB",
					"KB": "KB",
					"Tamaño máximo de archivo permitido:": "Maximum allowed file size:",
					"El tamaño del archivo supera el máximo permitido.": "The file size exceeds the allowed maximum.",
					"Proporción:": "Ratio:",
					"Formato:": "Format:",
					"Tamaño:": "Size",
					"máx.": "max.",
					"Desactivar negocio": "Disable business",
					"Activar negocio": "Enable business",
					"Activar evento": "Enable event",
					"Desactivar evento": "Disable event",
					"¿Seguro que quieres borrar este evento?": "Are you sure you want to delete this event",
					"Horario de apertura": "Opening hours",
                    "Ciudades": "Cities",
                    "Nueva ciudad": "New city",
                    "Borrar ciudad": "Delete city",
                    "Ciudades y zonas": "Cities and zones",
                    "Información sobre el negocio.": "Information about the business.",
                    "Activar categoría": "Enable category",
                    "Desactivar categoría": "Disable category",
                    "Descripción del evento.": "Event description.",
                    "Ofrece reservar en otros tipos de negocio complementarios al seleccionar este evento.": "Offers booking in other complementary business types when selecting this event.",
                    "Mostrar la selección de extras al seleccionar este evento.": "Show the selection of extras when selecting this event.",
                    "El cliente puede seleccionar más de una fecha consecutiva.": "The customer can select more than one consecutive date.",
                    "Una reserva de este evento conlleva la ocupación de todas las plazas.": "A reservation for this event implies occupying all the seats.",
                    "Lu": "Mon",
                    "Ma": "Tue",
                    "Mi": "Wed",
                    "Ju": "Thu",
                    "Vi": "Fri",
                    "Sa": "Sat",
                    "Do": "Sun",
                    "Introduce tu e-mail a continuación y enviaremos un enlace de verificación para que puedas cambiar tu contraseña:": "Enter your email below and we'll send a verification link so you can change your password:",
                    "No se ha podido enviar tu enlace de recuperación. Por favor comprueba que tus datos sean correctos y vuelve a intentarlo.": "Your recovery link could not be sent. Please check that your data is correct and try again.",
                    "Encuesta de satisfacción": "Satisfaction survey",
                    "Hemos enviado un enlace de verificación a tu cuenta de correo electrónico. Recuerda comprobar también la carpeta de spam.": "We have sent a verification link to your email account. Remember to also check your spam folder.",
                    "Deberás dar de alta las fechas manualmente en el apartado Disponibilidad": "You will need to manually enter the dates in the Availability section",
                    "Tícket medio por pedido": "Average ticket amount",
                    "Reservas totales": "Total orders",
                    "Porcentaje de reservas por país": "Ratio per country",
                    "Valoración global": "Global reviews",
                    "Filtrar por fechas": "Filter by date",
                    "Precio medio": "Average price",
                    "Ordenar por valoración": "Sort by rating",
                    "Ordenar alfabéticamente": "Sort by name",
                    "Actualizar": "Update",
                    "Valoración del evento": "Event rating",
                    "Media": "Average"
                }
            });

            if (sessionStorage.getItem("locale")) {
                L10n._Strings.setLanguage(sessionStorage.getItem("locale"));
            } else {
                sessionStorage.setItem("locale", L10n._Strings.getLanguage());
            }
        }
    }

    static SetLanguage(language) {
        return new Promise(async (resolve, reject) => {
			if (language == sessionStorage.getItem("locale")) {
				resolve();
				return;
			}

            if (!language) return;

            sessionStorage.setItem("locale", language);
			Session.AlterBootstrapData("language", language);
			await ContentHelper.InvalidateCache();
            L10n._Bootstrap();
            L10n._Strings.setLanguage(language);

            APIRequest("locale/set", { locale: language }).then(response => {
                resolve(response);
            });
        });
    }

    static GetDecimalSeparator() {
        switch (L10n.GetLanguage()) {
            case "es":
                return ",";
            default:
            case "en":
                return ".";
        }
    }

    static GetThousandsSeparator() {
        switch (L10n.GetLanguage()) {
            case "es":
                return ".";
            default:
            case "en":
                return ",";
        }
    }

    static GetLanguage() {
        if (!Session.GetCachedBootstrapData().supports_localization) {
            return L10n.GetAvailableLanguages()[0];
        }

        L10n._Bootstrap();
        return L10n._Strings.getLanguage();
    }

    static GetLocale() {
        const languageCode = L10n.GetLanguage();
        return languageCode + (languageCode == "es" ? "-ES" : "-US");
    }

    static GetAvailableLanguages() {
        L10n._Bootstrap();
        return L10n._Strings.getAvailableLanguages();
    }

    static __(str) {
        if (!Session.GetCachedBootstrapData().supports_localization) {
            return str;
        }
        
        L10n._Bootstrap();

        if (!L10n._Strings[str]) {
            console.log(str);
            return str;
        }
    
        return L10n._Strings[str];
    }
}
