import "../../css/home.css";

import React, { useContext, useEffect, useRef, useState } from "react";
import Footer from "./Footer.jsx";
import Events from "../services/Events.jsx";
import LoadingIndicator from "./LoadingIndicator.jsx";
import SearchResultsProvider from "../services/SearchResultsProvider.jsx";
import L10n from "../services/Locale";
import SubscriptionsService from "../services/Subscriptions";
import { SubscriptionsContext } from "../context/SubscriptionsContext";
import Session from "../services/Session";
import SubscriptionTypesBar from "./SubscriptionTypesBar";
import WidgetFiltro from "./WidgetFiltro";

export default function MisSuscripciones() {
	const [filteredResults, setFilteredResults] = useState([]);
	const [searchResultsProvider, _setSearchResultsProvider] = useState(new SearchResultsProvider(filteredResults, 3));
	const [venues, setVenues] = useState([]);
	const [ciudades, setCiudades] = useState([]);
	const [filtersActive, setFiltersActive] = useState(false);
	const [loading, setLoading] = useState(true);
	const [ eventsLoading, setEventsLoading ] = useState(true);
	const [ sortedTypes, setSortedTypes ] = useState([]);
	const [ selectedSubscription, setSelectedSubscription ] = useState(null);
    const widgetFiltroResetRef = useRef();

	const subscriptionsContext = useContext(SubscriptionsContext);
	const [ activeSubscriptionTypeIndex, setActiveSubscriptionTypeIndex ] = useState(-1);

	useEffect(() => {
		let subscriptionTypes = [...subscriptionsContext?.subscriptionTypes];
		subscriptionTypes.sort((a, b) => {
			let hasA, hasB;

			hasA = subscriptionsContext.userSubscriptions.filter(s => s.subscription_type_id == a.id).length > 0;
			hasB = subscriptionsContext.userSubscriptions.filter(s => s.subscription_type_id == b.id).length > 0;

			if (hasA && hasB) return a.name < b.name ? -1 : (a.name > b.name ? 1 : 0);
			if (hasA && !hasB) return -1;
			if (hasB && !hasA) return 1;

			return 0;
		});

		setActiveSubscriptionTypeIndex(0);
		setSortedTypes(subscriptionTypes);

		searchResultsProvider.setSubscriptionsContext(subscriptionsContext);
	}, [ subscriptionsContext ]);

	useEffect(() => {
		searchResultsProvider.setSelectedSubscriptionType(selectedSubscription);
	}, [ selectedSubscription ]);

	useEffect(() => {
		searchResultsProvider.setElementList(venues);
	}, [venues]);

	useEffect(() => {
		if (activeSubscriptionTypeIndex != -1 && activeSubscriptionTypeIndex < sortedTypes.length) {
			setEventsLoading(true);
			setSelectedSubscription(sortedTypes[activeSubscriptionTypeIndex]);

			setTimeout(() => {
				SubscriptionsService.SetCartData({
					typeSlug: sortedTypes[activeSubscriptionTypeIndex].slug,
					typeID: sortedTypes[activeSubscriptionTypeIndex].id,
					typeMode: sortedTypes[activeSubscriptionTypeIndex].pack == 1 ? SubscriptionsService.SUBSCRIPTION_MODE_TIPO : SubscriptionsService.SUBSCRIPTION_MODE_EVENTO
				});

				SubscriptionsService.GetEventsForType(sortedTypes[activeSubscriptionTypeIndex].id).then(venues => {
					venues.forEach((venue, venueIndex) => {
						venue.events.forEach((_, eventIndex) => {
							venues[venueIndex].events[eventIndex].loadAlways = true;
						});
					});

					Events.GetAvailabilityBulk(venues.reduce((vs, v) => vs.concat(v.events), []).map(e => e.id)).then(eventAvailabilities => {
						eventAvailabilities.forEach(eventAvailability => {
							venues.forEach((venue, venueIndex) => {
								venue.events.forEach((event, eventIndex) => {
									if (event.id == eventAvailability.event) {
										venues[venueIndex].events[eventIndex].availability = eventAvailability.availability;
										venues[venueIndex].events[eventIndex].calendar = eventAvailability.calendar;
										venues[venueIndex].events[eventIndex].loadAlways = false;
									}
								});
							});
						});

						setTimeout(() => {
							setEventsLoading(false);
						}, 150);
						
						const subscribedVenues = sortedTypes[activeSubscriptionTypeIndex].pack == 1 ? venues : venues?.filter(v => subscriptionsContext.userSubscriptions?.filter(s => s.events?.filter(e => e.negocio_id == v.id).length).length);

						setVenues(subscribedVenues);
						searchResultsProvider.setShowSubscriptionFee(sortedTypes[activeSubscriptionTypeIndex].pack != 1);
						searchResultsProvider.setShowAveragePrice(false);
						setLoading(false);
					});
				});
			}, 150);
		}
	}, [ activeSubscriptionTypeIndex ]);

	async function waitForSubscriptionsContext(callback) {
		if (!subscriptionsContext?.subscriptionsEnabled) {
			setTimeout(waitForSubscriptionsContext.bind(this, callback), 500);
			return;
		}

		callback();
	}

	useEffect(() => {
		Session.CheckIsLoggedIn().then(loggedIn => {
			if (!loggedIn) {
				Session.RequireLogin();
				return;
			}

			waitForSubscriptionsContext(() => {
				if (subscriptionsContext.userSubscriptions.length == 0) {
					setActiveSubscriptionTypeIndex(-1);
					return;
				}

				setActiveSubscriptionTypeIndex(0);
			});
			
			Events.GetCities().then(cities => {
				setCiudades(cities);
			});
		});
	}, []);

	if (loading) return <LoadingIndicator />;

	searchResultsProvider.resetRenderingOffsets();
	searchResultsProvider.resetTitleRendering();
	searchResultsProvider.setShowSubscriptionFee(false);
	searchResultsProvider.setShowAveragePrice(false);
	searchResultsProvider.setLinkToSubscriptionsOnlyPage(true);

	const noSubscriptions = searchResultsProvider.getResults().length == 0 && !eventsLoading;
    let maxPax = subscriptionsContext.userSubscriptions.filter(sub => sub.subscription_type_id == selectedSubscription.id).reduce((max, s) => Math.max(max, s.pax), 0);

	return (
		<div className="home mis-suscripciones">
			{!noSubscriptions && <WidgetFiltro
				ciudades={ciudades}
				elements={venues}
				resultsProvider={searchResultsProvider}
				onFiltered={(filtered, filtersActive) => {
					setFilteredResults(filtered);
					setFiltersActive(filtersActive);
                }}
                maxPax={maxPax}
                resetRef={ref => widgetFiltroResetRef.current = ref}
			/>}

			{subscriptionsContext?.subscriptionsEnabled && sortedTypes.length > 0 && <SubscriptionTypesBar
                onChange={idx => {
                    if (widgetFiltroResetRef.current) widgetFiltroResetRef.current();
					setEventsLoading(true);
					setTimeout(() => {
						setActiveSubscriptionTypeIndex(idx);
					}, 250);
				}}
				initialValue={activeSubscriptionTypeIndex}
				subscriptionTypes={sortedTypes}
			/>}

			{!noSubscriptions && selectedSubscription && <div style={{ opacity: eventsLoading ? 0 : 1, transition: "opacity 300ms" }}>
				<div className={"subscription-description"}>
					<div>
						<div>{selectedSubscription.short_description}</div>
					</div>
				</div>
				<h2 className="selected-subscription-name">{selectedSubscription.name}</h2>
			</div>}
			{noSubscriptions && <div className="subscription-description no-subscriptions has-button">
				<div>
					<div>{L10n.__("Actualmente no tienes ninguna suscripción activa.")}</div>
					<a href="/" className="cta btn btn-small">{L10n.__("Añadir suscripciones")}</a>
				</div>
			</div>}

			<section className="top-list bg-offwhite" style={{ opacity: eventsLoading ? 0 : 1 }}>
				{(filtersActive || (subscriptionsContext?.subscriptionsEnabled && selectedSubscription)) && searchResultsProvider.renderDefaultRows(true, false, 100)}
			</section>

			<section className="top-list bg-offwhite" style={{ opacity: eventsLoading ? 0 : 1 }}>{searchResultsProvider.resetTitleRendering() && searchResultsProvider.renderDefaultRows(!filtersActive)}</section>

			<Footer />
		</div>
	);
}
