import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Events from '../services/Events';
import Session from '../services/Session';
import LoadingIndicator from '../components/LoadingIndicator';
import L10n from '../services/Locale';
import $ from 'jquery';
import SubscriptionsService from '../services/Subscriptions';
import { APIRequest } from '../services/API';

export default class SuscripcionCompletada extends Component {
    constructor(props) {
        super(props);

        this.state = {
            user_data: {},
            confirmed: false
        };

        Session.GetSession().then(session => {
            this.setState({
                user_data: session
            });
        });

        let user_data = sessionStorage.getItem('user_data');
        if(user_data) {
            this.state.user_data = JSON.parse(user_data);
        }
    }

    componentDidMount() {
        $('header').addClass('simple');
        SubscriptionsService.ClearCartData();

        APIRequest("subscriptions/confirm", { setup_intent: location.search.split("&")?.find(p => p.indexOf("?setup_intent=") != -1)?.split("=")[1] }).then(() => {
            this.setState({ confirmed: true });
        });
    }

    componentWillUnmount() {
        $('header').removeClass('simple');
    }

    render() {
        if (!this.state.confirmed) {
            return <LoadingIndicator />;
        }

        return (
            <div className="reserva-completada">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h1>{L10n.__("Tu suscripción se ha completado con éxito")}</h1>
                            {this.state.user_data && <h2>{L10n.__("Tan pronto la confirmemos recibirás un correo de confirmación en")} <span className="user-email">{this.state.user_data.login}</span> {L10n.__("y en tu WhatsApp.")}<br/>{L10n.__("Recuerda comprobar también la carpeta de spam.")}</h2>}

                            <div className="buttons">
                                <a href="/mis-suscripciones" className="btn btn-brown">{L10n.__("Ver mis suscripciones")}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
