import { useEffect, useState, useContext, useRef } from "react";
import { SubscriptionsContext } from "../context/SubscriptionsContext";
import MaterialIcon from "./MaterialIcon";
import L10n from "../services/Locale";
import Helper from "../services/Helper";

export default function SubscriptionTypesBar(props) {
    const [ activeSubscriptionTypeIndex, setActiveSubscriptionTypeIndex ] = useState(props.initialValue ?? 0);
    const [ elementID, _setElementID ] = useState("subt-" + Math.floor(Math.random() * 10000 + 10000));
    const [ floating, setFloating ] = useState(false);
    const [ rightEnd, setRightEnd ] = useState(false);

    const animationInterval = useRef();
    const scrollOffset = useRef();

    const [ currentBarIndex, setCurrentBarIndex ] = useState(0);

    const subscriptionsContext = useContext(SubscriptionsContext);
    const subscriptionTypes = props.subscriptionTypes || subscriptionsContext.subscriptionTypes;

    useEffect(() => {
        const bodyPaddingTop = parseInt($("body").css("padding-top"));
        function onScroll(e) {
            if (floating) {
                if (window.scrollY == 0) {
                    setFloating(false);
                    $("body").css({ paddingTop: "" });
                }
            } else {
                if (window.scrollY > 0) {
                    setFloating(true);
                    $("body").css({ paddingTop: bodyPaddingTop + $("#" + elementID).outerHeight(true) });
                    $("#" + elementID).css({
                        top: $("header.desktop").outerHeight(true)
                    });
                }
            }
        }

        window.addEventListener("scroll", onScroll);

        return () => {
            window.removeEventListener("scroll", onScroll);
        }
    }, [ floating ]);

    useEffect(() => {
        const element = document.getElementById(elementID);
        const $container = $(element).find(".subscription-types-container");
        const $bar = $(element).find(".subscription-types-bar");
        $(element).css({ height: $container.outerHeight(true) + 35 });
        $bar.css({ height: $container.outerHeight(true) });
        $container.css({ position: "absolute" });

        if (currentBarIndex < 0) {
            setCurrentBarIndex(0);
            return;
        }

        if (currentBarIndex > subscriptionTypes.length - 1) {
            setCurrentBarIndex(subscriptionTypes.length - 1);
            return;
        }

        let elementsWidth = 0;
        $("#" + elementID + " .subscription-types-container > div").each(function(idx) {
            elementsWidth += $(this).outerWidth(true);
        });

        const maxOffset = $("#" + elementID + " .subscription-types-container > div").last()[0].getBoundingClientRect().left - $("#" + elementID + " .subscription-types-container")[0].getBoundingClientRect().left + $("#" + elementID + " .subscription-types-container > div").last()[0].getBoundingClientRect().width - $("#" + elementID + " .subscription-types-bar")[0].getBoundingClientRect().width;

        let offset = 0;
        $("#" + elementID + " .subscription-types-container > div").each(function(idx) {
            if (idx < currentBarIndex) {
                offset += $(this).outerWidth(true);
            }
        });

        scrollOffset.current = Math.min(0, -Math.min(offset, maxOffset));
        setRightEnd(scrollOffset.current == -maxOffset);
        
        $("#" + elementID + " .subscription-types-container").css({ left: scrollOffset.current });

        return () => {
            clearInterval(animationInterval.current);
        };
    }, [ currentBarIndex, subscriptionTypes ]);

    useEffect(() => {
        $("html, body").animate({ scrollTop: 0 }, 500);
    }, [activeSubscriptionTypeIndex]);

    useEffect(() => {
        document.body.classList.add("subscriptions-bar-visible");

        $("#subscription-types-bar-injected-css").remove();

        if (Helper.IsResponsive()) {
            $("head").append(`
                <style id="subscription-types-bar-injected-css">
                .subscription-types-bar-container.floating {
                    top: ${$("header.mobile").outerHeight(true)}px;
                }
                </style>
            `);
        }

        return () => {
            $("#subscription-types-bar-injected-css").remove();
            document.body.classList.remove("subscriptions-bar-visible");
        };
    }); // On render.

    return <div id={elementID} className={"subscription-types-bar-container " + (floating ? " floating" : "") + (currentBarIndex == 0 ? " left-end" : "") + (rightEnd ? " right-end" : "")}>
        <div className="subscription-types-bar">
            <MaterialIcon name="expand_circle_right" className="move-left" onClick={e => {
                e.preventDefault();
                setCurrentBarIndex(currentBarIndex - 6);
            }} />
            <div className="subscription-types-container">
                {subscriptionTypes?.map((type, idx) => {
                    let subscribed = subscriptionsContext.userSubscriptions.filter(t => t.subscription_type_id == type.id).length > 0;
                    
                    return <div className={"subscription-type" + (subscribed ? " subscribed" : "") + (activeSubscriptionTypeIndex == idx ? " active" : "")} key={"subscription-type-" + idx}
                        onClick={e => {
                            if (activeSubscriptionTypeIndex == idx) return;
                            if (props.onChange) props.onChange(idx);
                            setActiveSubscriptionTypeIndex(idx);
                        }}
                    >
                        <img src={type.image_url} />
                        <div className="name">{type.name}</div>
				        {type.pack == 1 && <div className="pack-tag">{L10n.__("Pack")}</div>}
                    </div>;
                })}
            </div>
            <MaterialIcon name="expand_circle_right" className="move-right" onClick={e => {
                e.preventDefault();
                setCurrentBarIndex(currentBarIndex + 6);
            }} />
        </div>
    </div>;
}
