import { APIRequest } from "../services/API";
import Session from "./Session";
import Helper from "./Helper";

export default class SubscriptionsService {

    static SUBSCRIPTION_MODE_TIPO = "tipo";
    static SUBSCRIPTION_MODE_EVENTO = "evento";

    static async IsEnabled() {
        const response = await APIRequest("subscriptions/enabled");
        return response.data;
    }

    static async GetTypes() {
        const response = await APIRequest("subscriptions/get-types");
        return response.data;
    }

    static async GetAllEvents(includeSingle=false) {
        const response = await APIRequest("subscriptions/get-all-events", { include_single: includeSingle });
		return response.data;
    }

    static async GetEventsForType(typeID, includeSingle=false) {
        const response = await APIRequest("subscriptions/get-events-for-type", { subscription_type_id: typeID, include_single: includeSingle });
		return response.data;
    }

    static async GetPageContents(slug, mode) {
        const response = await APIRequest("subscriptions/get-page-contents", { slug, mode });
		return JSON.parse(response.data || "{\"sections\":[]}") || {sections:[]};
    }

    static async RequireSubscriptionForEvent(eventID) {
        APIRequest("events/get-negocio-for-event", { id: eventID }).then(response => {
            sessionStorage.setItem("subscribe_redirect", location.href);
            location.href = "/suscribete/n/" + response.data.slug + "/" + eventID;
        });
    }

    static async RequireSubscriptionForAvailability(availabilityID, redirectURL=null) {
        APIRequest("events/get-event-slot", { availability_id: availabilityID }).then(response => {
            sessionStorage.setItem("subscribe_redirect", redirectURL || location.href);
            location.href = "/suscribete/n/" + response.data.negocio.slug + "/" + response.data.id;
        });
    }

    static async RequireSubscriptionForType(slug, redirectURL=null) {
        sessionStorage.setItem("subscribe_redirect", redirectURL || location.href);
        location.href = "/suscribete/t/" + slug;
    }

    static async RedirectAfterSubscribed() {
        const url = sessionStorage.getItem("subscribe_redirect");
        sessionStorage.removeItem("subscribe_redirect");
        location.href = url || "/";
    }

    static SetCartData(data) {
        const cartData = SubscriptionsService.GetCartData();

        for (let key of Object.keys(data)) {
            cartData[key] = data[key];
        }

        sessionStorage.setItem("subscription_cart_data", JSON.stringify(cartData || {}));
    }

    static GetCartData() {
        return JSON.parse(sessionStorage.getItem("subscription_cart_data") || "{}");
    }

    static ClearCartData() {
        sessionStorage.setItem("subscription_cart_data", JSON.stringify({}));
    }

    static FilterCalendar(calendar, eventID, userSubscriptions) {
        let filtered = {};

        for (let userSubscription of userSubscriptions.filter(s => s.events.filter(e => e.id == eventID).length > 0)) {
            const startDate = userSubscription.start_date.split("-");
            let month = parseInt(startDate[1]) + 1;
            let year = parseInt(startDate[0]);
            while (month > 12) {
                month -= 12;
                ++year;
            }
            const endDate = `${year}-${month}-${startDate[2]}`;

            for (let date of Object.keys(calendar)) {
                if (date >= startDate && date < endDate) {
                    filtered[date] = calendar[date];
                }
            }
        }

        return filtered;
    }

    static IsSubscribedToType(context, subscriptionType) {
        if (context.subscriptionsEnabled) {
            if (subscriptionType.pack == 1) {
                if (context.userSubscriptions.findIndex(t => t.id == subscriptionType.id) != -1) {
                    return true;
                }
            } else {
                if (context.userSubscriptions.filter(us => us.events.filter(usev => subscriptionType.events.findIndex(sev => sev.id == usev.id) != -1).length > 0).length > 0) {
                    return true;
                }
            }
        }
        
        return false;
    }

    static IsSubscribedToEvent(context, eventID) {
        if (context.subscriptionsEnabled) {
            if (context.userSubscriptions.filter(us => us.events.filter(usev => usev.id == eventID).length > 0).length > 0) {
                return true;
            }
        }

        return false;
    }

    static GetMaxPax(subscriptions, eventID) {
        return subscriptions.filter(sub => sub.events.filter(ev => ev.id == eventID).length > 0).reduce((max, sub) => Math.max(max, sub.pax), 0);
    }
}
